.footer-logos {
    color: var(--color-green);
    font-family: var(--font-secondary);
    font-style: 1.4rem;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex: 1 0;

    @media (max-width: 499px) {
        margin-top: 2rem;
        text-align: center;
        justify-content: center;
    }

    &__label {
        margin-right: .5rem;

        @media (max-width: 1350px) {
            flex-basis: 100%;
        }

        @media (--l-footer-bottom-center) and (min-width: 500px) {
            flex-basis: auto;
        }
    }
}
