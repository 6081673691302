.h-has-dynamic-content {
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: -2rem;
        left: 0;
        right: 0;
        bottom: -2rem;
        opacity: 0;
        z-index: -1;
        transition: opacity .3s ease;
        background-size: 6rem 6rem;
        background-repeat: repeat;
        background-image: linear-gradient(
                45deg,
                white  25%,
                transparent      25%,
                transparent      50%,
                white  50%,
                white  75%,
                transparent      75%,
                transparent
        );
        animation: loading .6s linear infinite;
    }

    &.-is-loading {
        &::after {
            opacity: 1;
            z-index: 1;
        }
    }
}

@keyframes loading {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 12rem 6rem;
    }
}
