.back-link {
    font-family: var(--font-secondary);
    font-size: 1.8rem;
    color: currentColor;
    line-height: 1.15;
    text-decoration: none;

    &::before {
        content: '';
        background-color: var(--color-primary);
        mask-image: url('../images/arrow.svg');
        mask-size: contain;
        mask-position: left bottom;
        display: inline-block;
        width: 2rem;
        height: 1em;
        margin-right: .8em;
    }

    &:--focus {
        color: var(--color-primary);
    }
}
