.downloads {
    font-size: 1.7rem;
    line-height: 1.25;
    border-bottom: 1px solid var(--color-tertiary-dark);
    margin-bottom: 4rem;

    > li {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid var(--color-tertiary-dark);
        align-items: flex-start;
        padding: 1em 0;

        @media (max-width: 379px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        > *:last-child {
            @media (min-width: 380px) {
                margin-right: 0;
                margin-left: 1rem;
                flex-shrink: 0;
            }
        }
    }

    .button {
        margin: 0;

        @media (max-width: 379px) {
            margin: 2rem auto 0;
        }
    }

    &__title {
        display: block;
        margin-bottom: .4em;
    }

    &__meta {
        display: block;
        color: var(--color-gray);
        font-size: 1.5rem;
        line-height: 1;
        font-family: var(--font-meta);
    }
}
