:root {
    --selection-colors-backgroundcolor: var(--color-secondary); // default: #b3d4fc
    --selection-colors-color: currentColor; // default: #000
}

::selection {
    background-color: color-mod(var(--selection-colors-backgroundcolor) a(90%));
    color: var(--selection-colors-color);
    text-shadow: none;
}
