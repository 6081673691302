%flash-message {
    font-weight: bold;
}

.flash-message--error {
    @extend %flash-message;
    color: var(--color-red);
}

.flash-message--success {
    @extend %flash-message;
    color: var(--color-green);
}
