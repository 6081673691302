.checklist {
    > li {
        position: relative;
        padding-left: 4rem;
        margin-bottom: 2rem;

        &::before, &::after {
            content: '';
            display: inline-block;
            position: absolute;
            width: 2.8rem;
            height: 2.8rem;
            left: 0;
            top: 0;
        }

        &::before {
            background-color: var(--color-white);
            border-radius: 50%;
        }

        &::after {
            mask-image: url('../images/check.svg');
            mask-position: center;
            mask-size: 1.5rem 1.3rem;
            background-color: var(--color-primary);
        }
    }
}
