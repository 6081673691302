%figure {
    img {
        margin-bottom: .5rem;
    }
}

figure:not([class]), .figure {
    @extend %figure;
}

%figcaption {
    font-size: 1.2rem;
}

figcaption:not([class]), .figcaption {
    @extend %figcaption;
}
