@use '../mixins/container';

.l-footer-top {
    @include container.inner();
    display: grid;
    gap: 5rem var(--grid-column-gap);
    grid-template:
        'footer1 footer2 footer3'
        / 1fr    2fr     1fr;
    padding-top: 5rem;
    padding-bottom: 5rem;
    font-size: 1.5rem;

    a {
        text-decoration: none;
    }

    @media (max-width: 1070px) {
        grid-template:
            'footer1 footer2'
            '.       footer3'
            / 1fr    2fr;
    }

    @media (max-width: 594px) {
        grid-template: none;
        grid-template-columns: 1fr;
    }

    @media (min-width: 595px) {
        @for $i from 1 through 13 {
            > :nth-child(#{$i}) {
                grid-area: footer#{$i};
            }
        }
    }

    &__menu {
        display: grid;
        grid-column-gap: var(--grid-column-gap);
        grid-template-columns: 1fr 1fr;

        @media (max-width: 400px) {
            grid-template-columns: 1fr;
        }
    }
}
