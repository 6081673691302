.highlight {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 100%;
    overflow: hidden;

    &.-has-title {
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 9em;
            background: linear-gradient(to top, rgba(0, 0, 0, .6) 0%, transparent 100%);
        }
    }

    picture:not([class]), .picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    img:not([class]) {
        background-image: var(--preload-image);
        background-size: cover;
        background-color: var(--color-green-dark); // To cover black background during load
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0;
        transition: transform .2s ease-in-out, opacity .15s ease-in-out;
    }

    &__title {
        z-index: var(--z-index-highlight-title);
        font-size: 1.8rem;
        font-family: var(--font-secondary);
        font-weight: var(--font-weight-regular);
        line-height: 1.15;
        transition: transform .2s ease-in-out;

        &::after {
            content: '';
            background-color: var(--color-secondary);
            mask-image: url('../images/arrow.svg');
            mask-size: contain;
            mask-position: center;
            display: inline-block;
            margin-left: .5rem;
            width: 1.8rem;
            height: 1.1rem;
            transform: scaleX(-1);
        }
    }
}

a.highlight {
    &:--focus {
        img {
            transform: scale3d(1.1, 1.1, 1.1);
        }

        .highlight__title {
            transform: translateY(-.5rem);
        }
    }
}
