%container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
}

/* Max site width without padding */
@mixin outer() {
    @extend %container;
    max-width: var(--outer-container-max-width);
}

/* Max content width with side padding */
@mixin inner() {
    @extend %container;
    max-width: var(--inner-container-max-width);
    padding-left: var(--inner-container-side-padding);
    padding-right: var(--inner-container-side-padding);
}
