%picture {
    background-color: var(--color-tertiary); // placeholder background color

    img {
        transition: filter .15s ease-in-out;
    }
}

picture:not([class]), .picture {
    @extend %picture;
}

a:--focus {
    picture:not([class]), .picture {
        > img {
            filter: brightness(70%);
        }
    }
}
