%paragraph {
    margin-bottom: 1.7em;
    margin-top: 0;
}

p:not([class]), .paragraph {
    @extend %paragraph;
}

.paragraph--intro {
    @extend %paragraph;
    font-size: 125%;
}
