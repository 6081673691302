@use '../mixins/container';

@custom-media --l-content-blocks-large (min-width: 1200px);
@custom-media --l-content-blocks-small (max-width: 779px);

%l-content-blocks {
    background-color: var(--color-tertiary);
    padding-bottom: 3rem;

    .l-content-blocks__inner {
        @include container.outer();
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media (--l-content-blocks-small) {
            display: block;
        }

        > * {
            padding: 4rem 5rem 5rem;
            max-width: 67rem;

            @media (--l-content-blocks-small) {
                margin: 0 auto;
            }

            @media (max-width: 500px) {
                padding-left: calc(var(--inner-container-side-padding) * 2);
                padding-right: calc(var(--inner-container-side-padding) * 2);
            }

            &:first-child {
                @media (--l-content-blocks-large) {
                    max-width: 53rem;
                }

                @media (--l-content-blocks-small) {
                    max-width: none;
                }
            }

            &:last-child {
                @media (--l-content-blocks-large) {
                    flex-basis: 60%;
                }

                @media (--l-content-blocks-small) {
                    max-width: none;
                }
            }
        }
    }
}

.l-content-blocks--single-block {
    @extend %l-content-blocks;

    .l-content-blocks__inner {
        > * {
            background-color: var(--color-white);
        }
    }
}

.l-content-blocks--login {
    @extend %l-content-blocks;
    padding-bottom: 0;

    @media (--l-content-blocks-large) {
        padding-top: 5rem;
        padding-bottom: 10rem;
    }

    .l-content-blocks__inner {
        > *:first-child {
            background-color: var(--color-white);
            flex-shrink: 0;
            flex-grow: 1;
        }
    }
}

.l-content-blocks--contact {
    @extend %l-content-blocks;

    @media (--l-content-blocks-large) {
        padding-top: 3rem;
    }

    .l-content-blocks__inner {
        > *:last-child {
            background-color: var(--color-white);
            flex-shrink: 0;
            flex-grow: 1;
        }
    }
}
