/* form__LABEL */
%label {
    display: inline-block;
    cursor: pointer;
    font-size: 1.7rem;
    line-height: 1;
    margin: 0 0 .5em;
    font-family: var(--font-secondary);
}

label:not([class]), .label {
    @extend %label;

    &.-padding-left {
        padding-left: 1.5em;
    }
}
