@use '../mixins/container';
@use '../mixins/responsive';
@use '../elements/button';

.l-cta {
    margin-top: 10rem;
}

.l-cta__top {
    color: var(--color-white);

    .l-cta__content {
        padding-top: 5rem;
    }
}

.l-cta__bottom {
    background-color: var(--color-tertiary);
    padding-bottom: 8rem;
    color: var(--color-tertiary-dark);

    .l-cta__content {
        padding-bottom: 5rem;
    }
}

.l-cta__content {
    @include responsive.size(font-size, 16, 20, 320, 842);
    @include responsive.size(padding-left, 20, 50, 320, 842);
    @include responsive.size(padding-right, 20, 50, 320, 842);
    text-align: center;
    background-color: var(--color-green-dark);
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    margin: 0 auto;
    max-width: 84.2rem;

    ::selection {
        color: var(--default-text-color);
    }

    button:not([class]), .button:not([class*='-color-']) {
        // Change default button color because of dark green background
        @include button.color-secondary();
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}
