.form__actions {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;

    a {
        font-size: 1.4rem;
    }
}
