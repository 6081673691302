@use '../mixins/container';
@use '../mixins/font';
@use '../mixins/responsive';
@use '../config/layout';

@custom-media --l-page-intro-image-mobile (max-width: 949px);
@custom-media --l-page-intro-image-desktop (min-width: 950px);

%l-page-intro {
    @include responsive.size(font-size, 17, 20, 320, 700);
    display: block;
    background-color: var(--color-tertiary);
    position: relative;

    &.-margin-top {
        margin-top: 12rem;
    }

    &.-margin-bottom {
        margin-bottom: 12rem;
    }

    &.-margin-huge {
        margin-top: 12rem;
        margin-bottom: 12rem;
    }

    .title--1, h1:not([class]) {
        margin-top: .42em;
        margin-bottom: .3em;

        &:first-child {
            margin-top: .42em;
        }
    }
}

.l-page-intro {
    @extend %l-page-intro;
}

.l-page-intro__inner {
    @include container.outer();
}

.l-page-intro__content {
    padding: 3.5rem var(--inner-container-side-padding) 4.1rem;
    text-align: center;

    > *:last-child:not([class]) {
        margin-bottom: 0;
    }

    > *:not(table) {
        max-width: 40.5em;
        margin-left: auto;
        margin-right: auto;
    }

    h1:not([class]), .title--1 {
        @include font.smoothing(on);
        max-width: 25em;
    }

    &:first-child:not(:last-child) {
        padding-bottom: 0;
    }

    &.-align-center {
        text-align: center;
    }

    @media (--l-page-intro-image-desktop) {
        &.-padding-extra {
            padding-top: 7rem;
            padding-bottom: 7rem;
        }
    }
}

.l-page-intro__bottom-image {
    display: block;
    max-width: 100%;
    margin: -3rem auto 0;
    position: relative;
    top: 6rem;
}

/**
 * Image min-height 100% van zichzelf, minstens 100% van layout block
 * Default image left
 */
.l-page-intro--image {
    @extend %l-page-intro;
    @include responsive.size(min-height, 312, 473, 950, 1440);
    background-color: transparent;
    margin-bottom: 5rem;

    .l-page-intro__outer {
        background-color: var(--color-tertiary);
    }

    // Split content in 2 parts so image can be put in between on mobile
    &.-split-content {
        .l-page-intro__content {
            &:last-child:not(:first-child) {
                padding-top: 0;
            }
        }
    }

    .l-page-intro__image {
        object-fit: cover;
        width: 100%;
    }

    @media (--l-page-intro-image-desktop) {
        .l-page-intro__inner {
            display: grid;
            grid-column-gap: var(--grid-column-gap);
            grid-template:
                'image content' max-content
                /1fr   1fr;

            > .l-page-intro__content {
                grid-area: content;
            }
        }

        &.-split-content {
            .l-page-intro__inner {
                grid-template:
                    'image content1' max-content
                    'image content2'
                    /1fr   1fr;

                &.-image-right {
                    grid-template:
                        'content1 image' max-content
                        'content2 image'
                        /1fr      1fr;

                    .l-page-intro__content {
                        padding-right: 0;
                    }
                }

                &:not(.-image-right) {
                    .l-page-intro__content {
                        padding-left: 0;
                    }
                }

                > :first-child {
                    grid-area: content1;
                }

                > :last-child {
                    grid-area: content2;
                }
            }
        }

        .l-page-intro__content {
            text-align: left;
        }

        .l-page-intro__image {
            grid-area: image;
            position: absolute;
            min-height: 100%;
            max-width: 100%;
            width: auto;

            &.-align-center {
                transform: translateY(-50%);
                top: 50%;
            }
        }
    }

    @media (--l-page-intro-image-mobile) {
        &.-split-content {
            .l-page-intro__image {
                margin-top: 4rem;
            }
        }

        .l-page-intro__image {
            max-height: 47.3rem;
        }
    }
}
