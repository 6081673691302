@mixin color-primary() {
    --button-color: var(--color-white);
    --button-background-color: var(--color-primary);
    --button-hover-background-color: var(--color-primary-dark);
}

@mixin color-secondary() {
    --button-color: var(--color-green-dark);
    --button-background-color: var(--color-secondary);
    --button-hover-background-color: var(--color-secondary-dark);
}

%button {
    @include color-primary();
    background-color: var(--button-background-color);
    border: 0;
    border-radius: 2.5rem;
    color: var(--button-color);
    cursor: pointer;
    display: inline-block;
    font-family: var(--font-secondary);
    font-size: 1.8rem;
    line-height: 1.2;
    margin: 1em 1em 1em 0;
    padding: 1.3rem 2.5rem 1.6rem;
    text-decoration: none;
    transition: background-color .2s ease-out;

    &:only-child {
        margin: 0;
    }

    &:--focus {
        outline: none;
        background-color: var(--button-hover-background-color);
    }

    &.-icon-download {
        padding-left: calc(2.5rem + 2rem + .67em); // button padding + icon width + margin right
        position: relative;
        text-align: left;

        &::before {
            content: '';
            display: inline-block;
            background-color: currentColor;
            mask-image: url('../images/download-icon.svg');
            mask-size: 2rem 100%;
            mask-repeat: no-repeat;
            mask-position: center;
            width: 2rem;
            height: 100%;
            position: absolute;
            top: -.25rem;
            left: 2.5rem;
        }
    }

    &.-icon-arrow {
        padding-right: calc(4rem + .67em);
        position: relative;
        text-align: left;

        &::before {
            content: '';
            background-color: currentColor;
            mask-image: url('../images/arrow.svg');
            mask-size: contain;
            mask-position: left bottom;
            display: inline-block;
            width: 1.8rem;
            height: 1.1rem;
            position: absolute;
            top: 1.9rem;
            right: 2.2rem;
            transform: scaleX(-1);
        }
    }

    &.-color-secondary {
        @include color-secondary();
    }

    &.-color-primary {
        @include color-primary();
    }
}

button:not([class]), .button {
    @extend %button;
}

.button--arrow {
    display: block;
    background-color: var(--button-background-color);
    color: var(--button-color);
    border-radius: 50%;
    padding: 0;
    width: 3.5rem;
    height: 3.5rem;
    text-indent: -999px;
    overflow: hidden;
    position: relative;

    &:only-child {
        margin: 0;
    }

    &:--focus {
        background-color: var(--button-hover-background-color);
    }

    &::before {
        content: '';
        background-color: currentColor;
        mask-image: url('../images/arrow.svg');
        mask-size: 1.8rem 1.1rem;
        mask-position: center;
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: scaleX(-1);
    }
}
