.menu-button {
    display: none;

    @media (--show-mobile-menu) {
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        color: var(--color-primary);
        font-family: var(--font-secondary);

        .-is-mobile-menu-open & {
            color: var(--color-white);
            align-self: flex-end;
        }

        .hamburger {
            margin-left: 1rem;
        }
    }
}
