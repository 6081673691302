@use '../mixins/helpers';

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
.h-hidden {
    display: none !important;
    visibility: hidden;
}

.h-visually-hidden {
    @include helpers.visually-hidden();
}

.h-visually-hidden--focusable {
    @include helpers.visually-hidden();

    &:active, &:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

.h-preload-image {
    background-image: var(--preload-image);
    background-size: cover;
}
