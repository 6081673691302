.product-specs {
    font-size: 1.4rem;
    line-height: 1.25;
    width: 100%;
    margin: 2em 0 0;
    table-layout: fixed ;

    th {
        color: var(--color-primary);
        font-weight: bold;
        padding-bottom: .4em;
        width: 33.3%;
    }
}
