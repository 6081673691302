/* stylelint-disable declaration-colon-space-after, max-empty-lines */

@custom-media --show-mobile-menu (max-width: 1129px);
@custom-media --show-desktop-menu (min-width: 1130px);

:root {
    --outer-container-max-width:    1400px;
    --inner-container-side-padding: 4vw;
    --inner-container-max-width:    calc(1400px + 2 * var(--inner-container-side-padding));

    --grid-column-gap:              3vw;
}
