@use '../mixins/container';

.l-header {
    @include container.inner();
    display: flex;

    @media (--show-mobile-menu) {
        justify-content: space-between;

        .-is-mobile-menu-open & {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: var(--z-index-mobile-header);
            background-color: var(--color-primary);
            color: var(--color-white);
            flex-direction: column;
            align-items: center;
            justify-content: start;
            overflow: auto;
            padding-top: 3.8rem;
        }
    }

    &__logo {
        padding: 1.3rem 1.3rem 1.8rem 0;

        @media (--show-mobile-menu) {
            .-is-mobile-menu-open & {
                display: none;
            }
        }
    }

    &__menu-primary {
        flex-grow: 1;

        @media (--show-mobile-menu) {
            display: none;
            flex-grow: 0;
            margin-top: 5vh;
            margin-bottom: 5vh;

            .-is-mobile-menu-open & {
                display: block;
            }
        }
    }

    &__menu-secondary {
        @media (--show-mobile-menu) {
            display: none;

            .-is-mobile-menu-open & {
                display: block;
            }
        }
    }
}
