.menu-list {
    line-height: 1.25;
    margin-top: -.4rem;

    > li {
        border-bottom: 1px solid var(--color-tertiary-dark);

        a {
            color: currentColor;
            display: inline-block;
            text-decoration: none;
            padding: 1rem 0;

            &:visited {
                color: currentColor;
            }

            &:--focus {
                color: var(--color-primary);
            }
        }
    }
}
