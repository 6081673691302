@use 'mixins/helpers';

.categories-filter {
    font-family: var(--font-secondary);
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    margin-bottom: .5rem;

    > li {
        display: block;
        margin-right: 1rem;
        margin-bottom: 1rem;
        position: relative;

        label, .categories-filter__label {
            cursor: pointer;
            line-height: 1.2;
            padding: .65em .5em;
            font-family: inherit; // for button
            color: inherit; // for button
            font-size: inherit; // for button

            &:--focus {
                color: black;
                outline: none;  // for button
            }
        }

        > label, .categories-filter__label {
            display: inline-block;
            border: 2px solid var(--color-tertiary);
            background-color: var(--color-tertiary);
            border-radius: .4rem;
            position: relative;

            &.-has-children {
                &::after {
                    content: '';
                    display: inline-block;
                    mask-image: url('../../images/dropdown-arrow.svg');
                    mask-size: .56em .37em;
                    mask-position: center;
                    width: .56em;
                    height: .47em;
                    margin-left: .5em;
                    background-color: currentColor;
                }
            }
        }

        &.-has-focus {
            &::after {
                content: '';
                display: block;
                width: 100%;
                background-color: var(--color-white);
                border: 2px solid var(--color-tertiary-dark);
                border-top: 0;
                border-bottom: 0;
                height: 13px;
                left: 0;
                position: absolute;
                transform: translateY(-4px);
                z-index: var(--z-index-categories-filter-item);
            }

            .categories-filter__label {
                background-color: var(--color-white);
                border-color: var(--color-tertiary-dark);
                border-bottom-color: var(--color-white);
            }

            .categories-filter__subcategories {
                max-height: none;
                border-width: 2px;
            }
        }
    }

    input {
        @include helpers.visually-hidden();

        &:checked {
            + label {
                background-color: var(--color-primary);
                border-color: var(--color-primary);
                color: var(--color-white);
            }
        }
    }

    &__subcategories {
        max-height: 0;
        overflow: hidden;
        font-family: var(--font-primary);
        font-size: 1.4rem;
        position: absolute;
        background-color: var(--color-white);
        border: 0 solid var(--color-tertiary-dark);
        border-radius: 0 .4rem .4rem;
        min-width: 100%;
        transform: translateY(7px);
        z-index: var(--z-index-categories-filter-subcategories);

        label {
            display: block;

            &:--focus {
                color: black;
            }
        }
    }
}
