.image {
    margin: 0;
    padding: 0;

    img {
        width: 100%;
        margin: 0;
    }

    figcaption {
        margin-top: .4rem;
        font-size: 1.3rem;
        line-height: 1.3;
        color: var(--color-blue-2);
    }
}

@media (--screen-medium-large) {
    .image {
        figcaption {
            font-size: 1.4rem;
            line-height: 1.5;
        }
    }
}
