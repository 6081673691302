$list-custom-color-dots: true;

%list {
    > li {
        margin: 0 0 .5em;
        padding: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

// Lists without class can get a default styling
ul:not([class]), ol:not([class]) {
    @extend %list;
    margin-bottom: 1.7em;
    overflow: hidden;
    padding-left: 1.5em;

    > li {
        display: list-item;
        margin-bottom: .75em;
    }
}

ul:not([class]), ul.list {
    @if $list-custom-color-dots {
        > li {
            position: relative;

            &::before {
                content: '';
                background-color: var(--color-primary);
                border-radius: 50%;
                display: block;
                height: .4rem;
                left: -1em;
                position: absolute;
                top: .76em;
                width: .4rem;
            }
        }
    } @else {
        list-style-type: disc;
    }
}

ol:not([class]), ol.list {
    list-style: decimal;
}
