/* Form Message */
.form__message {
    display: block;
    color: var(--color-red);
    padding: .8em .5em 0;
    margin-top: 0;
    font-size: 1.3rem;
    line-height: 1;

    .form__entry & {
        display: none;
    }

    [aria-invalid] + &, &.-invalid {
        display: block;
    }
}

textarea:not([class]) + .form__message {
    margin-top: -.7rem;
}
