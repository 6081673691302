.tags {
    &.-pull-up {
        margin-top: -1em;
    }

    &__list {
        display: inline-block;

        > li {
            display: inline-block;
            margin-bottom: .8rem;

            &:not(:last-child) {
                margin-right: .4rem;
            }
        }
    }

    &__filter-label {
        font-family: var(--font-secondary);
    }

    &__item {
        display: block; // needed for button
        font-size: 1.4rem;
        line-height: 1.1;
        background-color: var(--color-white);
        border-radius: .4rem;
        border: 1px solid var(--color-tertiary-dark);
        padding: .6rem .6rem .4rem;
        font-family: inherit;
        color: inherit;
    }
}

a.tags__item {
    text-decoration: none;

    &:--focus {
        border-color: var(--color-primary);
    }
}

label.tags__item {
    cursor: pointer;
    position: relative;
    padding-right: 1.5em;

    &::after {
        content: '';
        mask-image: url('../../images/cross.svg');
        background-color: currentColor;
        mask-size: 1rem;
        display: inline-block;
        width: .7em;
        height: 100%;
        mask-position: center;
        position: absolute;
        right: .6rem;
        top: .1rem;
    }

    &:--focus {
        &::after {
            background-color: var(--color-primary);
        }
    }
}
