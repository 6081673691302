@use "../../mixins/responsive";

:root {
    --navigation-color: var(--color-primary);
}

%navigation {
    line-height: 1.2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .navigation__list {
        @media (--show-mobile-menu) {
            .-is-mobile-menu-open & {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }

        > li {
            position: relative;

            @media (--show-desktop-menu) {
                @include responsive.size(margin-right, 20, 40, 1120);
                display: inline-block;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            @media (--show-mobile-menu) {
                margin-bottom: .6em;
            }

            @media (--show-desktop-menu) {
                &.-has-focus {
                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        background-color: var(--color-white);
                        height: .6em;
                        left: 0;
                        top: 1.3em;
                        position: absolute;
                        z-index: 1;
                    }

                    .navigation__sublist {
                        max-height: none;
                        border-width: 2px;
                    }
                }

                &.-has-children {
                    &::after {
                        content: '';
                        display: inline-block;
                        mask-image: url('../../images/dropdown-arrow.svg');
                        mask-size: .56em .37em;
                        mask-position: center;
                        width: .56em;
                        height: .47em;
                        margin-left: .5em;
                        background-color: currentColor;
                    }
                }
            }
        }
    }

    a {
        color: var(--navigation-color);
        text-decoration: none;

        &:visited {
            color: var(--navigation-color);
        }
    }

    .navigation__sublist {
        @media (--show-desktop-menu) {
            max-height: 0;
            overflow: hidden;
            font-family: var(--font-primary);
            font-size: 1.4rem;
            position: absolute;
            background-color: var(--color-white);
            border: 0 solid var(--color-tertiary-dark);
            border-radius: .4rem;
            min-width: 100%;
            transform: translateY(7px);
            z-index: 1;
            padding: .5em 0;

            li::before {
                display: none;
            }

            a {
                display: block;
                padding: .5em 1em;
                white-space: nowrap;
            }
        }
    }
}

.navigation--primary {
    @extend %navigation;
    font-size: 1.8rem;
    font-family: var(--font-secondary);

    @media (--show-desktop-menu) {
        a {
            &:--focus {
                color: var(--color-primary-dark);
            }
        }
    }

    .navigation__list > li {
        &.-is-active {
            border-bottom: .2rem solid var(--color-secondary);
        }
    }

    @media (--show-mobile-menu) {
        .-is-mobile-menu-open & {
            --navigation-color: var(--color-white);
            font-size: 2.7rem;
        }
    }
}

.navigation--secondary {
    --navigation-color: var(--default-text-color);

    @extend %navigation;
    font-size: 1.4rem;

    .navigation__list > li {
        padding-top: .4rem; // match height with primary navigation list items
        padding-bottom: .3rem;
    }

    @media (--show-desktop-menu) {
        a {
            &:--focus {
                color: var(--color-primary);
            }
        }
    }

    @media (--show-mobile-menu) {
        .-is-mobile-menu-open & {
            --navigation-color: var(--color-white);

            .navigation__sublist {
                text-align: center;

                li {
                    padding-top: .4rem;
                    padding-bottom: .3rem;
                    margin-top: .6em;
                }

                a {
                    opacity: .6;
                    font-size: 90%;
                }
            }
        }
    }
}
