@use '../mixins/font';
@use '../mixins/responsive';

:root {
    --title-link-focus-color: var(--color-green-dark);
}

%title {
    @include font.smoothing(off);
    color: var(--color-primary);
    display: block;
    font-family: var(--font-secondary);
    font-weight: var(--font-weight-regular);
    line-height: 1.25;
    margin-bottom: .8em;
    margin-top: 2em;
    position: relative;

    a {
        color: inherit;
        text-decoration: none;

        &:--focus {
            color: var(--title-link-focus-color);
            text-decoration: none;
        }
    }

    &:first-child {
        margin-top: 0;
    }
}

.title--1, h1:not([class]) {
    @extend %title;
    @include responsive.size(font-size, 30, 52);
}

.title--2, h2:not([class]) {
    @extend %title;
    @include responsive.size(font-size, 22, 34);
}

.title--3, h3:not([class]) {
    --title-link-focus-color: var(--color-primary);

    @extend %title;
    color: var(--default-text-color);
    @include responsive.size(font-size, 20, 24);
}

.title--4, h4:not([class]) {
    @extend %title;
    @include responsive.size(font-size, 18, 20);
}

.title--overview {
    @extend .title--4;
    margin-top: .5em;
    margin-bottom: .75em;
    padding-right: 5rem;

    &::after {
        content: '';
        display: inline-block;
        mask-image: url('../images/arrow.svg');
        background-color: currentColor;
        transform: scaleX(-1);
        position: absolute;
        right: 1rem;
        mask-size: contain;
        mask-position: left bottom;
        width: 2rem;
        height: 1em;
        top: 0;
        transition: transform .2s ease-in-out;

        a:--focus & {
            transform: scaleX(-1) translateX(-1rem);
        }
    }
}

.title--cta {
    @extend %title;
    @include responsive.size(font-size, 26, 48, 320, 842);
    color: currentColor;
}

.title--highlight {
    @extend %title;
    @include responsive.size(font-size, 22, 32);
    color: currentColor;
    margin-bottom: .7rem;
}

.title--footer {
    @extend .title--4;
    margin-bottom: 1.1em;
}
