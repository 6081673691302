@use '../../node_modules/glightbox/dist/css/glightbox.css';

.glightbox-clean .gslide-description {
    background-color: transparent;
}

.glightbox-clean .gslide-title {
    color: var(--color-white);
    margin: 0;
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 2rem;
}

.desc-top .gslide-image img, .desc-bottom .gslide-image img {
    max-height: 90vh;
    width: auto;
}

.glightbox-clean .gprev, .glightbox-clean .gnext, .glightbox-clean .gclose {
    background-color: var(--color-primary);

    &:--focus {
        background-color: var(--color-primary-dark);
    }
}
