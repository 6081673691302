@use '../mixins/container';

.l-overview-grid {
    @include container.inner();
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    font-size: 1.5rem;
    gap: 3.5rem;
    justify-content: center;
    margin-bottom: 4rem;

    a:not([class]) {
        text-decoration: none;
        color: var(--default-text-color);

        &:active, &:visited {
            color: inherit;
        }

        &:hover, &:focus {
            color: var(--color-primary);
        }
    }

    img {
        width: 100%;
    }

    &.-column-count-3 {
        @media (min-width: 1150px) {
            grid-template-columns: repeat(3, minmax(30rem, 35rem));
        }
    }

    &.-column-count-2 {
        @media (min-width: 915px) {
            grid-template-columns: repeat(2, minmax(30rem, 42rem));
        }
    }

    &.-column-count-1 {
        @media (min-width: 662px) {
            grid-template-columns: minmax(30rem, 45rem);
        }
    }
}
