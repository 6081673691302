@use "../mixins/font";

body {
    @include font.smoothing(); // Disable font-smoothing on places where you don't want it
    font-size: 1.6rem;
    line-height: 1.75;

    .-is-mobile-menu-open & {
        @media (--show-mobile-menu) {
            height: 100%;
        }
    }
}
