.image-gallery {
    gap: 1rem;
    margin-bottom: 4rem;

    @media (min-width: 380px) {
        column-count: 2;
    }

    @media (min-width: 600px) {
        column-count: 3;
    }

    a {
        display: inline-block;
        position: relative;

        img {
            display: block;
        }

        &:--focus {
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: color-mod(var(--color-primary) a(80%));
                background-image: url('../images/eye.svg');
                background-position: center;
                background-size: 2rem;
            }
        }
    }
}
