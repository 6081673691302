@use '../mixins/container';

@custom-media --l-footer-bottom-center (max-width: 1180px);

.l-footer-bottom {
    border-top: 1px solid var(--color-tertiary-dark);
    font-size: 1.5rem;

    a {
        text-decoration: none;
    }

    &__inner {
        @include container.inner();
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 1rem;

        @media (--l-footer-bottom-center) {
            padding-top: 2rem;
            flex-direction: column;
        }
    }
}
