.footer-links {
    @media (--l-footer-bottom-center) {
        text-align: center;
    }

    > * {
        word-wrap: none;

        &:not(:last-child) {
            margin-right: 1.5rem;
        }

        &:not(:first-child) {
            margin-left: 1.5rem;
        }
    }

    &__copy {
        @media (max-width: 850px) {
            display: block;
            margin-bottom: 2rem;
        }
    }
}
