:root {
    --font-primary:                     'Merriweather', serif;
    --font-secondary:                   'Patua One', serif;
    --font-meta:                        Arial;

    --font-weight-regular:              400;
    --font-weight-medium:               500;
    --font-weight-semi-bold:            600;
    --font-weight-bold:                 700;
    --font-weight-extra-bold:           800;
}
