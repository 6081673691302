%link {
    color: var(--color-primary);

    &:hover, &:focus {
        text-decoration: none;
    }

    &:visited {
        color: var(--color-primary-dark);
    }
}

a:not([class]), .link {
    @extend %link;
}
