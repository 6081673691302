/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --color-primary: var(--color-red);
    --color-primary-dark: #b1071b;
    --color-secondary: #ffe304;
    --color-secondary-dark: #edd300;
    --color-tertiary: #f4ede2;
    --color-tertiary-dark: #daccb5;

    --color-white: #ffffff;
    --color-green: #008e34;
    --color-red: #c70c22;
    --color-gray: #999a97;
    --color-green-dark: #494d34;

    --default-text-color: var(--color-green-dark);
}
