/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
  --color-primary: var(--color-red);
  --color-primary-dark: #b1071b;
  --color-secondary: #ffe304;
  --color-secondary-dark: #edd300;
  --color-tertiary: #f4ede2;
  --color-tertiary-dark: #daccb5;
  --color-white: #ffffff;
  --color-green: #008e34;
  --color-red: #c70c22;
  --color-gray: #999a97;
  --color-green-dark: #494d34;
  --default-text-color: var(--color-green-dark);
}

:root {
  --font-primary: "Merriweather", serif;
  --font-secondary: "Patua One", serif;
  --font-meta: Arial;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
}

/* stylelint-disable declaration-colon-space-after, max-empty-lines */
@custom-media --show-mobile-menu (max-width: 1129px);
@custom-media --show-desktop-menu (min-width: 1130px);
:root {
  --outer-container-max-width: 1400px;
  --inner-container-side-padding: 4vw;
  --inner-container-max-width: calc(1400px + 2 * var(--inner-container-side-padding));
  --grid-column-gap: 3vw;
}

@custom-selector :--focus :hover, :focus, :active;
:root {
  --z-index-mobile-header: 2;
  --z-index-highlight-title: 1;
  --z-index-categories-filter-item: 2;
  --z-index-categories-filter-subcategories: 2;
}

/**
 * You can use font-smoothing best when using light text on a dark background
 * This only works on Mac OS X
 *
 * More info:
 * - http://www.intridea.com/blog/2014/5/8/better-font-smoothing-in-chrome-on-mac-os-x
 * - http://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* stylelint-disable media-feature-name-no-vendor-prefix, media-feature-name-no-unknown, csstools/media-use-custom-media */
  /* stylelint-enable */
  font-size: 1.6rem;
  line-height: 1.75;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 200dpi), only screen and (min-resolution: 1.25dppx) {
  body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
@media (--show-mobile-menu) {
  .-is-mobile-menu-open body {
    height: 100%;
  }
}

button:not([class]), .button {
  --button-color: var(--color-white);
  --button-background-color: var(--color-primary);
  --button-hover-background-color: var(--color-primary-dark);
  background-color: var(--button-background-color);
  border: 0;
  border-radius: 2.5rem;
  color: var(--button-color);
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-secondary);
  font-size: 1.8rem;
  line-height: 1.2;
  margin: 1em 1em 1em 0;
  padding: 1.3rem 2.5rem 1.6rem;
  text-decoration: none;
  transition: background-color 0.2s ease-out;
}
button:only-child:not([class]), .button:only-child {
  margin: 0;
}
button:--focus:not([class]), .button:--focus {
  outline: none;
  background-color: var(--button-hover-background-color);
}
button.-icon-download:not([class]), .-icon-download.button {
  padding-left: calc(2.5rem + 2rem + .67em);
  position: relative;
  text-align: left;
}
button.-icon-download:not([class])::before, .-icon-download.button::before {
  content: "";
  display: inline-block;
  background-color: currentColor;
  mask-image: url("../images/download-icon.svg");
  mask-size: 2rem 100%;
  mask-repeat: no-repeat;
  mask-position: center;
  width: 2rem;
  height: 100%;
  position: absolute;
  top: -0.25rem;
  left: 2.5rem;
}
button.-icon-arrow:not([class]), .-icon-arrow.button {
  padding-right: calc(4rem + .67em);
  position: relative;
  text-align: left;
}
button.-icon-arrow:not([class])::before, .-icon-arrow.button::before {
  content: "";
  background-color: currentColor;
  mask-image: url("../images/arrow.svg");
  mask-size: contain;
  mask-position: left bottom;
  display: inline-block;
  width: 1.8rem;
  height: 1.1rem;
  position: absolute;
  top: 1.9rem;
  right: 2.2rem;
  transform: scaleX(-1);
}
button.-color-secondary:not([class]), .-color-secondary.button {
  --button-color: var(--color-green-dark);
  --button-background-color: var(--color-secondary);
  --button-hover-background-color: var(--color-secondary-dark);
}
button.-color-primary:not([class]), .-color-primary.button {
  --button-color: var(--color-white);
  --button-background-color: var(--color-primary);
  --button-hover-background-color: var(--color-primary-dark);
}

.button--arrow {
  display: block;
  background-color: var(--button-background-color);
  color: var(--button-color);
  border-radius: 50%;
  padding: 0;
  width: 3.5rem;
  height: 3.5rem;
  text-indent: -999px;
  overflow: hidden;
  position: relative;
}
.button--arrow:only-child {
  margin: 0;
}
.button--arrow:--focus {
  background-color: var(--button-hover-background-color);
}
.button--arrow::before {
  content: "";
  background-color: currentColor;
  mask-image: url("../images/arrow.svg");
  mask-size: 1.8rem 1.1rem;
  mask-position: center;
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(-1);
}

figure:not([class]) img, .figure img {
  margin-bottom: 0.5rem;
}

figcaption:not([class]), .figcaption {
  font-size: 1.2rem;
}

form:not([class]) button, .form button {
  margin-bottom: 0;
}

html {
  color: var(--default-text-color);
  font-family: var(--font-primary);
  font-size: 62.5%;
  scroll-behavior: smooth;
  /*@media (prefers-color-scheme: dark) {
      background-color: var(--color-green-dark);
      color: var(--color-white);
  }*/
}
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
@media (--show-mobile-menu) {
  html.-is-mobile-menu-open {
    height: 100%;
    overflow: hidden;
  }
}

/* form__FIELD */
textarea:not([class]),
.textarea, input:not([class]),
.input {
  font-size: 1.6rem;
  line-height: normal;
  margin: 0;
  width: 100%;
  padding: 1rem;
  font-family: var(--font-primary);
}

textarea:not([class]),
.textarea, input:not([class]),
.input {
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  padding: 1.2rem;
  word-break: normal;
  border: 1px solid var(--color-tertiary-dark);
  color: var(--default-text-color);
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
textarea:not([class])::placeholder,
.textarea::placeholder, input:not([class])::placeholder,
.input::placeholder {
  color: color-mod(var(--default-text-color) a(80%));
}
textarea:focus:not([class]),
.textarea:focus, input:focus:not([class]),
.input:focus {
  background-color: color-mod(var(--color-tertiary) a(20%));
  outline: none;
  border-color: var(--color-tertiary-dark);
}
textarea:focus:valid:not([class]),
.textarea:focus:valid, input:focus:valid:not([class]),
.input:focus:valid {
  border-color: var(--color-green-dark);
  background-color: color-mod(var(--color-green) a(15%));
}
textarea[aria-invalid]:not([class]),
[aria-invalid].textarea, input[aria-invalid]:not([class]),
[aria-invalid].input {
  border-color: var(--color-red);
  background-color: color-mod(var(--color-red) a(10%));
}

input:not([class])[type=checkbox], input:not([class])[type=radio],
.input[type=checkbox],
.input[type=radio] {
  display: inline-block;
  margin-left: 0.3rem;
  position: absolute;
  width: auto;
  margin-top: 0.7rem;
  left: 0;
}
input:not([class])[type=checkbox]:focus, input:not([class])[type=radio]:focus,
.input[type=checkbox]:focus,
.input[type=radio]:focus {
  outline: auto;
}

textarea:not([class]),
.textarea {
  line-height: 1.5;
}

/* form__LABEL */
label:not([class]), .label {
  display: inline-block;
  cursor: pointer;
  font-size: 1.7rem;
  line-height: 1;
  margin: 0 0 0.5em;
  font-family: var(--font-secondary);
}

label:not([class]).-padding-left, .label.-padding-left {
  padding-left: 1.5em;
}

hr:not([class]) {
  border: 0;
  border-top: 1px solid var(--color-tertiary-dark);
  display: block;
  margin-bottom: 1em;
  margin-top: 1.25em;
  padding: 0;
}

a:not([class]), .link {
  color: var(--color-primary);
}
a:hover:not([class]), .link:hover, a:focus:not([class]), .link:focus {
  text-decoration: none;
}
a:visited:not([class]), .link:visited {
  color: var(--color-primary-dark);
}

ul:not([class]) > li, ol:not([class]) > li {
  margin: 0 0 0.5em;
  padding: 0;
}
ul:not([class]) > li:last-child, ol:not([class]) > li:last-child {
  margin-bottom: 0;
}

ul:not([class]), ol:not([class]) {
  margin-bottom: 1.7em;
  overflow: hidden;
  padding-left: 1.5em;
}
ul:not([class]) > li, ol:not([class]) > li {
  display: list-item;
  margin-bottom: 0.75em;
}

ul:not([class]) > li, ul.list > li {
  position: relative;
}
ul:not([class]) > li::before, ul.list > li::before {
  content: "";
  background-color: var(--color-primary);
  border-radius: 50%;
  display: block;
  height: 0.4rem;
  left: -1em;
  position: absolute;
  top: 0.76em;
  width: 0.4rem;
}

ol:not([class]), ol.list {
  list-style: decimal;
}

.paragraph--intro, p:not([class]), .paragraph {
  margin-bottom: 1.7em;
  margin-top: 0;
}

.paragraph--intro {
  font-size: 125%;
}

picture:not([class]), .picture {
  background-color: var(--color-tertiary);
}
picture:not([class]) img, .picture img {
  transition: filter 0.15s ease-in-out;
}

a:--focus picture:not([class]) > img, a:--focus .picture > img {
  filter: brightness(70%);
}

:root {
  --selection-colors-backgroundcolor: var(--color-secondary);
  --selection-colors-color: currentColor;
}

::selection {
  background-color: color-mod(var(--selection-colors-backgroundcolor) a(90%));
  color: var(--selection-colors-color);
  text-shadow: none;
}

:root {
  /* stylelint-disable declaration-colon-space-after  */
  --table-border-color: transparent;
  --table-border-width: 0;
  --table-head-border-color: var(--color-tertiary-dark);
  --table-head-border-width: .1rem;
  --table-head-color: var(--default-text-color);
  --table-head-background-color: transparent;
  --table-row-border-color: transparent;
  --table-row-border-width: 0;
  --table-row-color: inherit;
  --table-row-background-color-even: var(--color-tertiary);
  --table-row-background-color-odd: transparent;
  /* stylelint-enable */
}

@custom-media --table-less-padding (width < 600px);
table:not([class]), .table {
  border: var(--table-border-width) solid var(--table-border-color);
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 90%;
  margin-bottom: 2em;
  margin-top: 2em;
  max-width: 100%;
  table-layout: fixed;
  width: 100%;
}
@media (--table-less-padding) {
  table:not([class]), .table {
    font-size: 80%;
  }
}
table:not([class]) tr:nth-child(even), .table tr:nth-child(even) {
  background-color: var(--table-row-background-color-even);
}
table:not([class]) tr:nth-child(odd), .table tr:nth-child(odd) {
  background-color: var(--table-row-background-color-odd);
}
table:not([class]) tr:hover th, table:not([class]) tr:hover td, .table tr:hover th, .table tr:hover td {
  color: var(--color-black);
}
table:not([class]) th, table:not([class]) td, .table th, .table td {
  padding: 0.9em 0.5em;
}
@media (--table-less-padding) {
  table:not([class]) th, table:not([class]) td, .table th, .table td {
    padding: 0.5em 0.25em;
  }
}
table:not([class]) th, .table th {
  background: var(--table-head-background-color);
  border-bottom: var(--table-head-border-width) solid var(--table-head-border-color);
  color: var(--table-head-color);
  text-align: left;
  vertical-align: middle;
}
table:not([class]) td, .table td {
  border-bottom: var(--table-row-border-width) solid var(--table-row-border-color);
  color: var(--table-row-color);
}
table:not([class]) tr:last-child td, .table tr:last-child td {
  border-bottom-color: var(--table-border-color);
}
table:not([class]) thead th, .table thead th {
  vertical-align: bottom;
}
table:not([class]) tbody th, table:not([class]) tbody td, .table tbody th, .table tbody td {
  vertical-align: top;
}

:root {
  --title-link-focus-color: var(--color-green-dark);
}

.title--highlight, .title--cta, .title--4, .title--footer, .title--overview, h4:not([class]), .title--3, h3:not([class]), .title--2, h2:not([class]), .title--1, h1:not([class]) {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  color: var(--color-primary);
  display: block;
  font-family: var(--font-secondary);
  font-weight: var(--font-weight-regular);
  line-height: 1.25;
  margin-bottom: 0.8em;
  margin-top: 2em;
  position: relative;
}
.title--highlight a, .title--cta a, .title--4 a, .title--footer a, .title--overview a, h4:not([class]) a, .title--3 a, h3:not([class]) a, .title--2 a, h2:not([class]) a, .title--1 a, h1:not([class]) a {
  color: inherit;
  text-decoration: none;
}
.title--highlight a:--focus, .title--cta a:--focus, .title--4 a:--focus, .title--footer a:--focus, .title--overview a:--focus, h4:not([class]) a:--focus, .title--3 a:--focus, h3:not([class]) a:--focus, .title--2 a:--focus, h2:not([class]) a:--focus, .title--1 a:--focus, h1:not([class]) a:--focus {
  color: var(--title-link-focus-color);
  text-decoration: none;
}
.title--highlight:first-child, .title--cta:first-child, .title--4:first-child, .title--footer:first-child, .title--overview:first-child, h4:first-child:not([class]), .title--3:first-child, h3:first-child:not([class]), .title--2:first-child, h2:first-child:not([class]), .title--1:first-child, h1:first-child:not([class]) {
  margin-top: 0;
}

.title--1, h1:not([class]) {
  font-size: calc(3rem + (52 - 30) * ((100vw - 32rem) / (1440 - 320)));
}
@media (min-width: 1440px) {
  .title--1, h1:not([class]) {
    font-size: 5.2rem;
  }
}

.title--2, h2:not([class]) {
  font-size: calc(2.2rem + (34 - 22) * ((100vw - 32rem) / (1440 - 320)));
}
@media (min-width: 1440px) {
  .title--2, h2:not([class]) {
    font-size: 3.4rem;
  }
}

.title--3, h3:not([class]) {
  --title-link-focus-color: var(--color-primary);
  color: var(--default-text-color);
  font-size: calc(2rem + (24 - 20) * ((100vw - 32rem) / (1440 - 320)));
}
@media (min-width: 1440px) {
  .title--3, h3:not([class]) {
    font-size: 2.4rem;
  }
}

.title--4, .title--footer, .title--overview, h4:not([class]) {
  font-size: calc(1.8rem + (20 - 18) * ((100vw - 32rem) / (1440 - 320)));
}
@media (min-width: 1440px) {
  .title--4, .title--footer, .title--overview, h4:not([class]) {
    font-size: 2rem;
  }
}

.title--overview {
  margin-top: 0.5em;
  margin-bottom: 0.75em;
  padding-right: 5rem;
}
.title--overview::after {
  content: "";
  display: inline-block;
  mask-image: url("../images/arrow.svg");
  background-color: currentColor;
  transform: scaleX(-1);
  position: absolute;
  right: 1rem;
  mask-size: contain;
  mask-position: left bottom;
  width: 2rem;
  height: 1em;
  top: 0;
  transition: transform 0.2s ease-in-out;
}
a:--focus .title--overview::after {
  transform: scaleX(-1) translateX(-1rem);
}

.title--cta {
  font-size: calc(2.6rem + (48 - 26) * ((100vw - 32rem) / (842 - 320)));
  color: currentColor;
}
@media (min-width: 842px) {
  .title--cta {
    font-size: 4.8rem;
  }
}

.title--highlight {
  font-size: calc(2.2rem + (32 - 22) * ((100vw - 32rem) / (1440 - 320)));
  color: currentColor;
  margin-bottom: 0.7rem;
}
@media (min-width: 1440px) {
  .title--highlight {
    font-size: 3.2rem;
  }
}

.title--footer {
  margin-bottom: 1.1em;
}

.l-page-intro__inner, .l-overview-grid, .l-highlights-grid, .l-header, .l-footer-top, .l-footer-bottom__inner, .l-filter__options-inner, .l-filter__selection, .l-content-blocks--single-block .l-content-blocks__inner, .l-content-blocks--login .l-content-blocks__inner, .l-content-blocks--contact .l-content-blocks__inner, .l-container--inner, .l-container--outer, .l-article {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
}

/* Max site width without padding */
/* Max content width with side padding */
.l-article {
  max-width: var(--inner-container-max-width);
  padding-left: var(--inner-container-side-padding);
  padding-right: var(--inner-container-side-padding);
  max-width: 43.6em;
}

.l-container--inner {
  max-width: var(--inner-container-max-width);
  padding-left: var(--inner-container-side-padding);
  padding-right: var(--inner-container-side-padding);
}

.l-container--outer {
  max-width: var(--outer-container-max-width);
}

@custom-media --l-content-blocks-large (min-width: 1200px);
@custom-media --l-content-blocks-small (max-width: 779px);
.l-content-blocks--contact, .l-content-blocks--login, .l-content-blocks--single-block {
  background-color: var(--color-tertiary);
  padding-bottom: 3rem;
}
.l-content-blocks--contact .l-content-blocks__inner, .l-content-blocks--login .l-content-blocks__inner, .l-content-blocks--single-block .l-content-blocks__inner {
  max-width: var(--outer-container-max-width);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (--l-content-blocks-small) {
  .l-content-blocks--contact .l-content-blocks__inner, .l-content-blocks--login .l-content-blocks__inner, .l-content-blocks--single-block .l-content-blocks__inner {
    display: block;
  }
}
.l-content-blocks--contact .l-content-blocks__inner > *, .l-content-blocks--login .l-content-blocks__inner > *, .l-content-blocks--single-block .l-content-blocks__inner > * {
  padding: 4rem 5rem 5rem;
  max-width: 67rem;
}
@media (--l-content-blocks-small) {
  .l-content-blocks--contact .l-content-blocks__inner > *, .l-content-blocks--login .l-content-blocks__inner > *, .l-content-blocks--single-block .l-content-blocks__inner > * {
    margin: 0 auto;
  }
}
@media (max-width: 500px) {
  .l-content-blocks--contact .l-content-blocks__inner > *, .l-content-blocks--login .l-content-blocks__inner > *, .l-content-blocks--single-block .l-content-blocks__inner > * {
    padding-left: calc(var(--inner-container-side-padding) * 2);
    padding-right: calc(var(--inner-container-side-padding) * 2);
  }
}
@media (--l-content-blocks-large) {
  .l-content-blocks--contact .l-content-blocks__inner > *:first-child, .l-content-blocks--login .l-content-blocks__inner > *:first-child, .l-content-blocks--single-block .l-content-blocks__inner > *:first-child {
    max-width: 53rem;
  }
}
@media (--l-content-blocks-small) {
  .l-content-blocks--contact .l-content-blocks__inner > *:first-child, .l-content-blocks--login .l-content-blocks__inner > *:first-child, .l-content-blocks--single-block .l-content-blocks__inner > *:first-child {
    max-width: none;
  }
}
@media (--l-content-blocks-large) {
  .l-content-blocks--contact .l-content-blocks__inner > *:last-child, .l-content-blocks--login .l-content-blocks__inner > *:last-child, .l-content-blocks--single-block .l-content-blocks__inner > *:last-child {
    flex-basis: 60%;
  }
}
@media (--l-content-blocks-small) {
  .l-content-blocks--contact .l-content-blocks__inner > *:last-child, .l-content-blocks--login .l-content-blocks__inner > *:last-child, .l-content-blocks--single-block .l-content-blocks__inner > *:last-child {
    max-width: none;
  }
}

.l-content-blocks--single-block .l-content-blocks__inner > * {
  background-color: var(--color-white);
}

.l-content-blocks--login {
  padding-bottom: 0;
}
@media (--l-content-blocks-large) {
  .l-content-blocks--login {
    padding-top: 5rem;
    padding-bottom: 10rem;
  }
}
.l-content-blocks--login .l-content-blocks__inner > *:first-child {
  background-color: var(--color-white);
  flex-shrink: 0;
  flex-grow: 1;
}

@media (--l-content-blocks-large) {
  .l-content-blocks--contact {
    padding-top: 3rem;
  }
}
.l-content-blocks--contact .l-content-blocks__inner > *:last-child {
  background-color: var(--color-white);
  flex-shrink: 0;
  flex-grow: 1;
}

.l-cta {
  margin-top: 10rem;
}

.l-cta__top {
  color: var(--color-white);
}
.l-cta__top .l-cta__content {
  padding-top: 5rem;
}

.l-cta__bottom {
  background-color: var(--color-tertiary);
  padding-bottom: 8rem;
  color: var(--color-tertiary-dark);
}
.l-cta__bottom .l-cta__content {
  padding-bottom: 5rem;
}

.l-cta__content {
  font-size: calc(1.6rem + (20 - 16) * ((100vw - 32rem) / (842 - 320)));
  padding-left: calc(2rem + (50 - 20) * ((100vw - 32rem) / (842 - 320)));
  padding-right: calc(2rem + (50 - 20) * ((100vw - 32rem) / (842 - 320)));
  text-align: center;
  background-color: var(--color-green-dark);
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  margin: 0 auto;
  max-width: 84.2rem;
}
@media (min-width: 842px) {
  .l-cta__content {
    font-size: 2rem;
  }
}
@media (min-width: 842px) {
  .l-cta__content {
    padding-left: 5rem;
  }
}
@media (min-width: 842px) {
  .l-cta__content {
    padding-right: 5rem;
  }
}
.l-cta__content ::selection {
  color: var(--default-text-color);
}
.l-cta__content button:not([class]), .l-cta__content .button:not([class*=-color-]) {
  --button-color: var(--color-green-dark);
  --button-background-color: var(--color-secondary);
  --button-hover-background-color: var(--color-secondary-dark);
}
.l-cta__content > *:first-child {
  margin-top: 0;
}
.l-cta__content > *:last-child {
  margin-bottom: 0;
}

.l-filter__options {
  border-bottom: 2px solid var(--color-tertiary);
}
.l-filter__options-inner {
  max-width: var(--inner-container-max-width);
  padding-left: var(--inner-container-side-padding);
  padding-right: var(--inner-container-side-padding);
}
.l-filter__selection {
  max-width: var(--inner-container-max-width);
  padding-left: var(--inner-container-side-padding);
  padding-right: var(--inner-container-side-padding);
  padding-top: 5rem;
  padding-bottom: 3rem;
}

@custom-media --l-footer-bottom-center (max-width: 1180px);
.l-footer-bottom {
  border-top: 1px solid var(--color-tertiary-dark);
  font-size: 1.5rem;
}
.l-footer-bottom a {
  text-decoration: none;
}
.l-footer-bottom__inner {
  max-width: var(--inner-container-max-width);
  padding-left: var(--inner-container-side-padding);
  padding-right: var(--inner-container-side-padding);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}
@media (--l-footer-bottom-center) {
  .l-footer-bottom__inner {
    padding-top: 2rem;
    flex-direction: column;
  }
}

.l-footer-top {
  max-width: var(--inner-container-max-width);
  padding-left: var(--inner-container-side-padding);
  padding-right: var(--inner-container-side-padding);
  display: grid;
  gap: 5rem var(--grid-column-gap);
  grid-template: "footer1 footer2 footer3"/1fr 2fr 1fr;
  padding-top: 5rem;
  padding-bottom: 5rem;
  font-size: 1.5rem;
}
.l-footer-top a {
  text-decoration: none;
}
@media (max-width: 1070px) {
  .l-footer-top {
    grid-template: "footer1 footer2" ".       footer3"/1fr 2fr;
  }
}
@media (max-width: 594px) {
  .l-footer-top {
    grid-template: none;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 595px) {
  .l-footer-top > :nth-child(1) {
    grid-area: footer1;
  }
  .l-footer-top > :nth-child(2) {
    grid-area: footer2;
  }
  .l-footer-top > :nth-child(3) {
    grid-area: footer3;
  }
  .l-footer-top > :nth-child(4) {
    grid-area: footer4;
  }
  .l-footer-top > :nth-child(5) {
    grid-area: footer5;
  }
  .l-footer-top > :nth-child(6) {
    grid-area: footer6;
  }
  .l-footer-top > :nth-child(7) {
    grid-area: footer7;
  }
  .l-footer-top > :nth-child(8) {
    grid-area: footer8;
  }
  .l-footer-top > :nth-child(9) {
    grid-area: footer9;
  }
  .l-footer-top > :nth-child(10) {
    grid-area: footer10;
  }
  .l-footer-top > :nth-child(11) {
    grid-area: footer11;
  }
  .l-footer-top > :nth-child(12) {
    grid-area: footer12;
  }
  .l-footer-top > :nth-child(13) {
    grid-area: footer13;
  }
}
.l-footer-top__menu {
  display: grid;
  grid-column-gap: var(--grid-column-gap);
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 400px) {
  .l-footer-top__menu {
    grid-template-columns: 1fr;
  }
}

.l-header {
  max-width: var(--inner-container-max-width);
  padding-left: var(--inner-container-side-padding);
  padding-right: var(--inner-container-side-padding);
  display: flex;
}
@media (--show-mobile-menu) {
  .l-header {
    justify-content: space-between;
  }
  .-is-mobile-menu-open .l-header {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: var(--z-index-mobile-header);
    background-color: var(--color-primary);
    color: var(--color-white);
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow: auto;
    padding-top: 3.8rem;
  }
}
.l-header__logo {
  padding: 1.3rem 1.3rem 1.8rem 0;
}
@media (--show-mobile-menu) {
  .-is-mobile-menu-open .l-header__logo {
    display: none;
  }
}
.l-header__menu-primary {
  flex-grow: 1;
}
@media (--show-mobile-menu) {
  .l-header__menu-primary {
    display: none;
    flex-grow: 0;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  .-is-mobile-menu-open .l-header__menu-primary {
    display: block;
  }
}
@media (--show-mobile-menu) {
  .l-header__menu-secondary {
    display: none;
  }
  .-is-mobile-menu-open .l-header__menu-secondary {
    display: block;
  }
}

@custom-media --l-highlights-grid-4 (min-width: 1100px);
@custom-media --l-highlights-grid-3 (min-width: 800px);
@custom-media --l-highlights-grid-2 (min-width: 550px);
@custom-media --l-highlights-grid-1 (max-width: 549px);
:root {
  --highlights-grid-background-color: var(--color-green-dark);
  --highlights-grid-background-color-hover: color-mod(var(--color-green-dark) shade(20%));
}

.l-highlights-grid {
  max-width: var(--outer-container-max-width);
  color: var(--color-white);
  font-size: 1.5rem;
  display: grid;
  gap: 0.8rem;
  margin-bottom: 4rem;
}
@media (--l-highlights-grid-1) {
  .l-highlights-grid {
    grid-template: none;
  }
}
@media (--l-highlights-grid-2) {
  .l-highlights-grid {
    grid-template: "highlight1  highlight1" "highlight1  highlight1" "highlight2  highlight3" "highlight2  highlight4" "highlight5  highlight6" "highlight5  highlight7" "highlight8  highlight8" "highlight9  highlight9" "highlight10 highlight11" "highlight12 highlight13"/1fr 1fr;
  }
}
@media (--l-highlights-grid-3) {
  .l-highlights-grid {
    grid-template: "highlight1  highlight1  highlight2" "highlight1  highlight1  highlight2" "highlight5  highlight3  highlight4" "highlight5  highlight9  highlight9" "highlight6  highlight8  highlight8" "highlight7  highlight10 highlight11" "highlight12 highlight10 highlight13"/1fr 1fr 1fr;
  }
}
@media (--l-highlights-grid-4) {
  .l-highlights-grid {
    grid-template: "highlight1  highlight1  highlight2  highlight3" "highlight1  highlight1  highlight2  highlight4" "highlight5  highlight6  highlight8  highlight8" "highlight5  highlight9  highlight9  highlight7" "highlight10 highlight11 highlight12 highlight13"/1fr 1fr 1fr 1fr;
  }
}
.l-highlights-grid > * {
  background-color: var(--highlights-grid-background-color);
  padding: 3rem;
  min-height: 34.4rem;
}
@media (--l-highlights-grid-2) {
  .l-highlights-grid > *:first-child {
    min-height: 68.8rem;
  }
}
.l-highlights-grid > *.-background-color-alt {
  --highlights-grid-background-color: var(--color-primary);
  --highlights-grid-background-color-hover: color-mod(var(--color-primary) shade(20%));
}
.l-highlights-grid > * > *:first-child {
  margin-top: 0;
}
.l-highlights-grid > * > *:last-child {
  margin-bottom: 0;
}
.l-highlights-grid > a:--focus {
  background-color: var(--highlights-grid-background-color-hover);
}
@media (--l-highlights-grid-2) {
  .l-highlights-grid > :nth-child(1) {
    grid-area: highlight1;
  }
  .l-highlights-grid > :nth-child(2) {
    grid-area: highlight2;
  }
  .l-highlights-grid > :nth-child(3) {
    grid-area: highlight3;
  }
  .l-highlights-grid > :nth-child(4) {
    grid-area: highlight4;
  }
  .l-highlights-grid > :nth-child(5) {
    grid-area: highlight5;
  }
  .l-highlights-grid > :nth-child(6) {
    grid-area: highlight6;
  }
  .l-highlights-grid > :nth-child(7) {
    grid-area: highlight7;
  }
  .l-highlights-grid > :nth-child(8) {
    grid-area: highlight8;
  }
  .l-highlights-grid > :nth-child(9) {
    grid-area: highlight9;
  }
  .l-highlights-grid > :nth-child(10) {
    grid-area: highlight10;
  }
  .l-highlights-grid > :nth-child(11) {
    grid-area: highlight11;
  }
  .l-highlights-grid > :nth-child(12) {
    grid-area: highlight12;
  }
  .l-highlights-grid > :nth-child(13) {
    grid-area: highlight13;
  }
}
.l-highlights-grid a {
  color: currentColor;
  text-decoration: none;
}
.l-highlights-grid a:visited {
  color: currentColor;
}
.l-highlights-grid p {
  margin-bottom: 1em;
}
.l-highlights-grid button:not([class]), .l-highlights-grid .button:not([class*=-color-]), .l-highlights-grid .button--arrow:not([class*=-color-]) {
  --button-color: var(--color-green-dark);
  --button-background-color: var(--color-secondary);
  --button-hover-background-color: var(--color-secondary-dark);
}

.l-overview-grid {
  max-width: var(--inner-container-max-width);
  padding-left: var(--inner-container-side-padding);
  padding-right: var(--inner-container-side-padding);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  font-size: 1.5rem;
  gap: 3.5rem;
  justify-content: center;
  margin-bottom: 4rem;
}
.l-overview-grid a:not([class]) {
  text-decoration: none;
  color: var(--default-text-color);
}
.l-overview-grid a:not([class]):active, .l-overview-grid a:not([class]):visited {
  color: inherit;
}
.l-overview-grid a:not([class]):hover, .l-overview-grid a:not([class]):focus {
  color: var(--color-primary);
}
.l-overview-grid img {
  width: 100%;
}
@media (min-width: 1150px) {
  .l-overview-grid.-column-count-3 {
    grid-template-columns: repeat(3, minmax(30rem, 35rem));
  }
}
@media (min-width: 915px) {
  .l-overview-grid.-column-count-2 {
    grid-template-columns: repeat(2, minmax(30rem, 42rem));
  }
}
@media (min-width: 662px) {
  .l-overview-grid.-column-count-1 {
    grid-template-columns: minmax(30rem, 45rem);
  }
}

@custom-media --l-page-intro-image-mobile (max-width: 949px);
@custom-media --l-page-intro-image-desktop (min-width: 950px);
.l-page-intro--image, .l-page-intro {
  font-size: calc(1.7rem + (20 - 17) * ((100vw - 32rem) / (700 - 320)));
  display: block;
  background-color: var(--color-tertiary);
  position: relative;
}
@media (min-width: 700px) {
  .l-page-intro--image, .l-page-intro {
    font-size: 2rem;
  }
}
.-margin-top.l-page-intro--image, .-margin-top.l-page-intro {
  margin-top: 12rem;
}
.-margin-bottom.l-page-intro--image, .-margin-bottom.l-page-intro {
  margin-bottom: 12rem;
}
.-margin-huge.l-page-intro--image, .-margin-huge.l-page-intro {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
.l-page-intro--image .title--1, .l-page-intro .title--1, .l-page-intro--image h1:not([class]), .l-page-intro h1:not([class]) {
  margin-top: 0.42em;
  margin-bottom: 0.3em;
}
.l-page-intro--image .title--1:first-child, .l-page-intro .title--1:first-child, .l-page-intro--image h1:not([class]):first-child, .l-page-intro h1:not([class]):first-child {
  margin-top: 0.42em;
}

.l-page-intro__inner {
  max-width: var(--outer-container-max-width);
}

.l-page-intro__content {
  padding: 3.5rem var(--inner-container-side-padding) 4.1rem;
  text-align: center;
}
.l-page-intro__content > *:last-child:not([class]) {
  margin-bottom: 0;
}
.l-page-intro__content > *:not(table) {
  max-width: 40.5em;
  margin-left: auto;
  margin-right: auto;
}
.l-page-intro__content h1:not([class]), .l-page-intro__content .title--1 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* stylelint-disable media-feature-name-no-vendor-prefix, media-feature-name-no-unknown, csstools/media-use-custom-media */
  /* stylelint-enable */
  max-width: 25em;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 200dpi), only screen and (min-resolution: 1.25dppx) {
  .l-page-intro__content h1:not([class]), .l-page-intro__content .title--1 {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
.l-page-intro__content:first-child:not(:last-child) {
  padding-bottom: 0;
}
.l-page-intro__content.-align-center {
  text-align: center;
}
@media (--l-page-intro-image-desktop) {
  .l-page-intro__content.-padding-extra {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}

.l-page-intro__bottom-image {
  display: block;
  max-width: 100%;
  margin: -3rem auto 0;
  position: relative;
  top: 6rem;
}

/**
 * Image min-height 100% van zichzelf, minstens 100% van layout block
 * Default image left
 */
.l-page-intro--image {
  min-height: calc(31.2rem + (473 - 312) * ((100vw - 95rem) / (1440 - 950)));
  background-color: transparent;
  margin-bottom: 5rem;
}
@media (min-width: 1440px) {
  .l-page-intro--image {
    min-height: 47.3rem;
  }
}
.l-page-intro--image .l-page-intro__outer {
  background-color: var(--color-tertiary);
}
.l-page-intro--image.-split-content .l-page-intro__content:last-child:not(:first-child) {
  padding-top: 0;
}
.l-page-intro--image .l-page-intro__image {
  object-fit: cover;
  width: 100%;
}
@media (--l-page-intro-image-desktop) {
  .l-page-intro--image .l-page-intro__inner {
    display: grid;
    grid-column-gap: var(--grid-column-gap);
    grid-template: "image content" max-content/1fr 1fr;
  }
  .l-page-intro--image .l-page-intro__inner > .l-page-intro__content {
    grid-area: content;
  }
  .l-page-intro--image.-split-content .l-page-intro__inner {
    grid-template: "image content1" max-content "image content2"/1fr 1fr;
  }
  .l-page-intro--image.-split-content .l-page-intro__inner.-image-right {
    grid-template: "content1 image" max-content "content2 image"/1fr 1fr;
  }
  .l-page-intro--image.-split-content .l-page-intro__inner.-image-right .l-page-intro__content {
    padding-right: 0;
  }
  .l-page-intro--image.-split-content .l-page-intro__inner:not(.-image-right) .l-page-intro__content {
    padding-left: 0;
  }
  .l-page-intro--image.-split-content .l-page-intro__inner > :first-child {
    grid-area: content1;
  }
  .l-page-intro--image.-split-content .l-page-intro__inner > :last-child {
    grid-area: content2;
  }
  .l-page-intro--image .l-page-intro__content {
    text-align: left;
  }
  .l-page-intro--image .l-page-intro__image {
    grid-area: image;
    position: absolute;
    min-height: 100%;
    max-width: 100%;
    width: auto;
  }
  .l-page-intro--image .l-page-intro__image.-align-center {
    transform: translateY(-50%);
    top: 50%;
  }
}
@media (--l-page-intro-image-mobile) {
  .l-page-intro--image.-split-content .l-page-intro__image {
    margin-top: 4rem;
  }
  .l-page-intro--image .l-page-intro__image {
    max-height: 47.3rem;
  }
}

.back-link {
  font-family: var(--font-secondary);
  font-size: 1.8rem;
  color: currentColor;
  line-height: 1.15;
  text-decoration: none;
}
.back-link::before {
  content: "";
  background-color: var(--color-primary);
  mask-image: url("../images/arrow.svg");
  mask-size: contain;
  mask-position: left bottom;
  display: inline-block;
  width: 2rem;
  height: 1em;
  margin-right: 0.8em;
}
.back-link:--focus {
  color: var(--color-primary);
}

.browser-update {
  background: #cccccc;
  color: #000000;
  display: none;
  margin: 0.2rem 0;
  padding: 0.2rem 1rem;
  text-align: center;
  /* stylelint-disable csstools/media-use-custom-media */
  /* styleline-enable */
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .browser-update {
    display: block;
  }
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 * Allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
 */
.categories-filter {
  font-family: var(--font-secondary);
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
.categories-filter > li {
  display: block;
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative;
}
.categories-filter > li label, .categories-filter > li .categories-filter__label {
  cursor: pointer;
  line-height: 1.2;
  padding: 0.65em 0.5em;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
}
.categories-filter > li label:--focus, .categories-filter > li .categories-filter__label:--focus {
  color: black;
  outline: none;
}
.categories-filter > li > label, .categories-filter > li .categories-filter__label {
  display: inline-block;
  border: 2px solid var(--color-tertiary);
  background-color: var(--color-tertiary);
  border-radius: 0.4rem;
  position: relative;
}
.categories-filter > li > label.-has-children::after, .categories-filter > li .categories-filter__label.-has-children::after {
  content: "";
  display: inline-block;
  mask-image: url("../../images/dropdown-arrow.svg");
  mask-size: 0.56em 0.37em;
  mask-position: center;
  width: 0.56em;
  height: 0.47em;
  margin-left: 0.5em;
  background-color: currentColor;
}
.categories-filter > li.-has-focus::after {
  content: "";
  display: block;
  width: 100%;
  background-color: var(--color-white);
  border: 2px solid var(--color-tertiary-dark);
  border-top: 0;
  border-bottom: 0;
  height: 13px;
  left: 0;
  position: absolute;
  transform: translateY(-4px);
  z-index: var(--z-index-categories-filter-item);
}
.categories-filter > li.-has-focus .categories-filter__label {
  background-color: var(--color-white);
  border-color: var(--color-tertiary-dark);
  border-bottom-color: var(--color-white);
}
.categories-filter > li.-has-focus .categories-filter__subcategories {
  max-height: none;
  border-width: 2px;
}
.categories-filter input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.1rem;
}
.categories-filter input:checked + label {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);
}
.categories-filter__subcategories {
  max-height: 0;
  overflow: hidden;
  font-family: var(--font-primary);
  font-size: 1.4rem;
  position: absolute;
  background-color: var(--color-white);
  border: 0 solid var(--color-tertiary-dark);
  border-radius: 0 0.4rem 0.4rem;
  min-width: 100%;
  transform: translateY(7px);
  z-index: var(--z-index-categories-filter-subcategories);
}
.categories-filter__subcategories label {
  display: block;
}
.categories-filter__subcategories label:--focus {
  color: black;
}

.checklist > li {
  position: relative;
  padding-left: 4rem;
  margin-bottom: 2rem;
}
.checklist > li::before, .checklist > li::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 2.8rem;
  height: 2.8rem;
  left: 0;
  top: 0;
}
.checklist > li::before {
  background-color: var(--color-white);
  border-radius: 50%;
}
.checklist > li::after {
  mask-image: url("../images/check.svg");
  mask-position: center;
  mask-size: 1.5rem 1.3rem;
  background-color: var(--color-primary);
}

.downloads {
  font-size: 1.7rem;
  line-height: 1.25;
  border-bottom: 1px solid var(--color-tertiary-dark);
  margin-bottom: 4rem;
}
.downloads > li {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-tertiary-dark);
  align-items: flex-start;
  padding: 1em 0;
}
@media (max-width: 379px) {
  .downloads > li {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
@media (min-width: 380px) {
  .downloads > li > *:last-child {
    margin-right: 0;
    margin-left: 1rem;
    flex-shrink: 0;
  }
}
.downloads .button {
  margin: 0;
}
@media (max-width: 379px) {
  .downloads .button {
    margin: 2rem auto 0;
  }
}
.downloads__title {
  display: block;
  margin-bottom: 0.4em;
}
.downloads__meta {
  display: block;
  color: var(--color-gray);
  font-size: 1.5rem;
  line-height: 1;
  font-family: var(--font-meta);
}

.image {
  margin: 0;
  padding: 0;
}
.image img {
  width: 100%;
  margin: 0;
}
.image figcaption {
  margin-top: 0.4rem;
  font-size: 1.3rem;
  line-height: 1.3;
  color: var(--color-blue-2);
}

@media (--screen-medium-large) {
  .image figcaption {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
.flash-message--success, .flash-message--error {
  font-weight: bold;
}

.flash-message--error {
  color: var(--color-red);
}

.flash-message--success {
  color: var(--color-green);
}

@media (--l-footer-bottom-center) {
  .footer-links {
    text-align: center;
  }
}
.footer-links > * {
  word-wrap: none;
}
.footer-links > *:not(:last-child) {
  margin-right: 1.5rem;
}
.footer-links > *:not(:first-child) {
  margin-left: 1.5rem;
}
@media (max-width: 850px) {
  .footer-links__copy {
    display: block;
    margin-bottom: 2rem;
  }
}

.footer-logos {
  color: var(--color-green);
  font-family: var(--font-secondary);
  font-style: 1.4rem;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex: 1 0;
}
@media (max-width: 499px) {
  .footer-logos {
    margin-top: 2rem;
    text-align: center;
    justify-content: center;
  }
}
.footer-logos__label {
  margin-right: 0.5rem;
}
@media (max-width: 1350px) {
  .footer-logos__label {
    flex-basis: 100%;
  }
}
@media (--l-footer-bottom-center) and (min-width: 500px) {
  .footer-logos__label {
    flex-basis: auto;
  }
}

.form__actions {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
}
.form__actions a {
  font-size: 1.4rem;
}

.form__entry {
  margin-bottom: var(--form-row-gap);
  position: relative;
}

/* Form Message */
.form__message {
  display: block;
  color: var(--color-red);
  padding: 0.8em 0.5em 0;
  margin-top: 0;
  font-size: 1.3rem;
  line-height: 1;
}
.form__entry .form__message {
  display: none;
}
[aria-invalid] + .form__message, .form__message.-invalid {
  display: block;
}

textarea:not([class]) + .form__message {
  margin-top: -0.7rem;
}

.form__row {
  display: grid;
  gap: 0 var(--form-column-gap);
  align-items: baseline;
}

.form__row {
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
}

:root {
  --form-row-gap: 2rem;
  --form-column-gap: 3rem;
}

:root {
  --hamburger-layer-color: var(--color-primary-dark);
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 1;
}
.hamburger.is-active:hover {
  opacity: 1;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: var(--hamburger-layer-color);
}

.hamburger-box {
  width: 19px;
  height: 17px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 19px;
  height: 3px;
  background-color: var(--hamburger-layer-color);
  border-radius: 3px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -7px;
}
.hamburger-inner::after {
  bottom: -7px;
}

/*
 * Spin
 */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger {
  display: block;
  outline: none;
  transition: transform 0.3s ease-in-out;
}
.hamburger:not(.is-active) .hamburger-inner {
  width: 14px;
}
.hamburger.is-active .hamburger-box {
  transform: translateY(2px);
}
@media (--show-mobile-menu) {
  .-is-mobile-menu-open .hamburger {
    --hamburger-layer-color: var(--color-white);
  }
}

.hamburger-label {
  margin-right: 1rem;
  display: inline-block;
}

.highlight {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;
  overflow: hidden;
}
.highlight.-has-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 9em;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
}
.highlight picture:not([class]), .highlight .picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.highlight img:not([class]) {
  background-image: var(--preload-image);
  background-size: cover;
  background-color: var(--color-green-dark);
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  transition: transform 0.2s ease-in-out, opacity 0.15s ease-in-out;
}
.highlight__title {
  z-index: var(--z-index-highlight-title);
  font-size: 1.8rem;
  font-family: var(--font-secondary);
  font-weight: var(--font-weight-regular);
  line-height: 1.15;
  transition: transform 0.2s ease-in-out;
}
.highlight__title::after {
  content: "";
  background-color: var(--color-secondary);
  mask-image: url("../images/arrow.svg");
  mask-size: contain;
  mask-position: center;
  display: inline-block;
  margin-left: 0.5rem;
  width: 1.8rem;
  height: 1.1rem;
  transform: scaleX(-1);
}

a.highlight:--focus img {
  transform: scale3d(1.1, 1.1, 1.1);
}
a.highlight:--focus .highlight__title {
  transform: translateY(-0.5rem);
}

.image-gallery {
  gap: 1rem;
  margin-bottom: 4rem;
}
@media (min-width: 380px) {
  .image-gallery {
    column-count: 2;
  }
}
@media (min-width: 600px) {
  .image-gallery {
    column-count: 3;
  }
}
.image-gallery a {
  display: inline-block;
  position: relative;
}
.image-gallery a img {
  display: block;
}
.image-gallery a:--focus::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: color-mod(var(--color-primary) a(80%));
  background-image: url("../images/eye.svg");
  background-position: center;
  background-size: 2rem;
}

.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: none;
  overflow: hidden;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

.glightbox-container .gslider {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom,
.glightbox-container .ginner-container.desc-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.glightbox-container .ginner-container.desc-left,
.glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe,
.gslide video {
  outline: none !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto;
}

.gslide-image {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  display: block;
  padding: 0;
  float: none;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  margin: auto;
  min-width: 200px;
}

.desc-top .gslide-image img,
.desc-bottom .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img,
.desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
  /* max-width: 160vmin; */
  margin: auto;
}

.gslide-video::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none;
}

.gslide-video.playing::before {
  display: none;
}

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 75vh;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  background: #fff;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%;
}

.gslide-inline .ginlined-content {
  padding: 20px;
  width: 100%;
}

.gslide-inline .dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1;
}

.gslide-external {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%;
}

.gslide-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.desc-top .gslide-media,
.desc-bottom .gslide-media {
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gslide-description {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.gslide-description.description-left,
.gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom,
.gslide-description.description-top {
  margin: 0 auto;
  width: 100%;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description {
  display: none;
}

.glightbox-button-hidden {
  display: none;
}

/*
 * Description for mobiles
 * something like facebook does the description
 * for the photos
*/
.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  background: transparent;
  position: absolute;
  bottom: 15px;
  padding: 19px 11px;
  max-width: 100vw !important;
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.75)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  padding-bottom: 50px;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: bold;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc string {
  color: #fff;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: 0.4;
}

.gdesc-open .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 0.4;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.greset {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  -webkit-animation: lightboxLoader 0.8s infinite linear;
  animation: lightboxLoader 0.8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gprev,
.gnext,
.gclose {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gprev svg,
.gnext svg,
.gclose svg {
  display: block;
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
}

.gprev.disabled,
.gnext.disabled,
.gclose.disabled {
  opacity: 0.1;
}

.gprev .garrow,
.gnext .garrow,
.gclose .garrow {
  stroke: #fff;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gnext,
.glightbox-closing .gprev,
.glightbox-closing .gclose {
  opacity: 0 !important;
}

/*Skin */
.glightbox-clean .gslide-description {
  background: #fff;
}

.glightbox-clean .gdesc-inner {
  padding: 22px 20px;
}

.glightbox-clean .gslide-title {
  font-size: 1em;
  font-weight: normal;
  font-family: arial;
  color: #000;
  margin-bottom: 19px;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  font-size: 0.86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gprev,
.glightbox-clean .gnext,
.glightbox-clean .gclose {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}

.glightbox-clean .gprev path,
.glightbox-clean .gnext path,
.glightbox-clean .gclose path {
  fill: #fff;
}

.glightbox-clean button:focus:not(.focused):not(.disabled) {
  outline: none;
}

.glightbox-clean .gprev {
  position: absolute;
  top: -100%;
  left: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gnext {
  position: absolute;
  top: -100%;
  right: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  top: 15px;
  right: 10px;
  position: absolute;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}

/*CSS Animations*/
.gfadeIn {
  -webkit-animation: gfadeIn 0.5s ease;
  animation: gfadeIn 0.5s ease;
}

.gfadeOut {
  -webkit-animation: gfadeOut 0.5s ease;
  animation: gfadeOut 0.5s ease;
}

.gslideOutLeft {
  -webkit-animation: gslideOutLeft 0.3s ease;
  animation: gslideOutLeft 0.3s ease;
}

.gslideInLeft {
  -webkit-animation: gslideInLeft 0.3s ease;
  animation: gslideInLeft 0.3s ease;
}

.gslideOutRight {
  -webkit-animation: gslideOutRight 0.3s ease;
  animation: gslideOutRight 0.3s ease;
}

.gslideInRight {
  -webkit-animation: gslideInRight 0.3s ease;
  animation: gslideInRight 0.3s ease;
}

.gzoomIn {
  -webkit-animation: gzoomIn 0.5s ease;
  animation: gzoomIn 0.5s ease;
}

.gzoomOut {
  -webkit-animation: gzoomOut 0.5s ease;
  animation: gzoomOut 0.5s ease;
}

@-webkit-keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .glightbox-container .ginner-container.desc-top .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .glightbox-container .ginner-container.desc-top .gslide-image,
.glightbox-container .ginner-container.desc-top .gslide-image img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .glightbox-container .ginner-container.desc-left .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .glightbox-container .ginner-container.desc-left .gslide-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .gslide-image img {
    max-height: 97vh;
    max-width: 100%;
  }

  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }

  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab;
  }

  .gslide-inline {
    max-height: 95vh;
  }

  .gslide-external {
    max-height: 100vh;
  }

  .gslide-description.description-left,
.gslide-description.description-right {
    max-width: 275px;
  }

  .glightbox-open {
    height: auto;
  }

  .goverlay {
    background: rgba(0, 0, 0, 0.92);
  }

  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
  }

  .glightbox-clean .description-left .gdesc-inner,
.glightbox-clean .description-right .gdesc-inner {
    position: absolute;
    height: 100%;
    overflow-y: auto;
  }

  .glightbox-clean .gprev,
.glightbox-clean .gnext,
.glightbox-clean .gclose {
    background-color: rgba(0, 0, 0, 0.32);
  }

  .glightbox-clean .gprev:hover,
.glightbox-clean .gnext:hover,
.glightbox-clean .gclose:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .glightbox-clean .gprev {
    top: 45%;
  }

  .glightbox-clean .gnext {
    top: 45%;
  }
}
@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: 0.7;
    right: 20px;
  }
}
@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}
.glightbox-clean .gslide-description {
  background-color: transparent;
}

.glightbox-clean .gslide-title {
  color: var(--color-white);
  margin: 0;
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 2rem;
}

.desc-top .gslide-image img, .desc-bottom .gslide-image img {
  max-height: 90vh;
  width: auto;
}

.glightbox-clean .gprev, .glightbox-clean .gnext, .glightbox-clean .gclose {
  background-color: var(--color-primary);
}
.glightbox-clean .gprev:--focus, .glightbox-clean .gnext:--focus, .glightbox-clean .gclose:--focus {
  background-color: var(--color-primary-dark);
}

.menu-button {
  display: none;
}
@media (--show-mobile-menu) {
  .menu-button {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    color: var(--color-primary);
    font-family: var(--font-secondary);
  }
  .-is-mobile-menu-open .menu-button {
    color: var(--color-white);
    align-self: flex-end;
  }
  .menu-button .hamburger {
    margin-left: 1rem;
  }
}

.menu-list {
  line-height: 1.25;
  margin-top: -0.4rem;
}
.menu-list > li {
  border-bottom: 1px solid var(--color-tertiary-dark);
}
.menu-list > li a {
  color: currentColor;
  display: inline-block;
  text-decoration: none;
  padding: 1rem 0;
}
.menu-list > li a:visited {
  color: currentColor;
}
.menu-list > li a:--focus {
  color: var(--color-primary);
}

:root {
  --navigation-color: var(--color-primary);
}

.navigation--secondary, .navigation--primary {
  line-height: 1.2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (--show-mobile-menu) {
  .-is-mobile-menu-open .navigation--secondary .navigation__list, .-is-mobile-menu-open .navigation--primary .navigation__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.navigation--secondary .navigation__list > li, .navigation--primary .navigation__list > li {
  position: relative;
}
@media (--show-desktop-menu) {
  .navigation--secondary .navigation__list > li, .navigation--primary .navigation__list > li {
    margin-right: calc(2rem + (40 - 20) * ((100vw - 112rem) / (1440 - 1120)));
    display: inline-block;
  }
  .navigation--secondary .navigation__list > li:last-of-type, .navigation--primary .navigation__list > li:last-of-type {
    margin-right: 0;
  }
}
@media (--show-desktop-menu) and (min-width: 1440px) {
  .navigation--secondary .navigation__list > li, .navigation--primary .navigation__list > li {
    margin-right: 4rem;
  }
}
@media (--show-mobile-menu) {
  .navigation--secondary .navigation__list > li, .navigation--primary .navigation__list > li {
    margin-bottom: 0.6em;
  }
}
@media (--show-desktop-menu) {
  .navigation--secondary .navigation__list > li.-has-focus::before, .navigation--primary .navigation__list > li.-has-focus::before {
    content: "";
    display: block;
    width: 100%;
    background-color: var(--color-white);
    height: 0.6em;
    left: 0;
    top: 1.3em;
    position: absolute;
    z-index: 1;
  }
  .navigation--secondary .navigation__list > li.-has-focus .navigation__sublist, .navigation--primary .navigation__list > li.-has-focus .navigation__sublist {
    max-height: none;
    border-width: 2px;
  }
  .navigation--secondary .navigation__list > li.-has-children::after, .navigation--primary .navigation__list > li.-has-children::after {
    content: "";
    display: inline-block;
    mask-image: url("../../images/dropdown-arrow.svg");
    mask-size: 0.56em 0.37em;
    mask-position: center;
    width: 0.56em;
    height: 0.47em;
    margin-left: 0.5em;
    background-color: currentColor;
  }
}
.navigation--secondary a, .navigation--primary a {
  color: var(--navigation-color);
  text-decoration: none;
}
.navigation--secondary a:visited, .navigation--primary a:visited {
  color: var(--navigation-color);
}
@media (--show-desktop-menu) {
  .navigation--secondary .navigation__sublist, .navigation--primary .navigation__sublist {
    max-height: 0;
    overflow: hidden;
    font-family: var(--font-primary);
    font-size: 1.4rem;
    position: absolute;
    background-color: var(--color-white);
    border: 0 solid var(--color-tertiary-dark);
    border-radius: 0.4rem;
    min-width: 100%;
    transform: translateY(7px);
    z-index: 1;
    padding: 0.5em 0;
  }
  .navigation--secondary .navigation__sublist li::before, .navigation--primary .navigation__sublist li::before {
    display: none;
  }
  .navigation--secondary .navigation__sublist a, .navigation--primary .navigation__sublist a {
    display: block;
    padding: 0.5em 1em;
    white-space: nowrap;
  }
}

.navigation--primary {
  font-size: 1.8rem;
  font-family: var(--font-secondary);
}
@media (--show-desktop-menu) {
  .navigation--primary a:--focus {
    color: var(--color-primary-dark);
  }
}
.navigation--primary .navigation__list > li.-is-active {
  border-bottom: 0.2rem solid var(--color-secondary);
}
@media (--show-mobile-menu) {
  .-is-mobile-menu-open .navigation--primary {
    --navigation-color: var(--color-white);
    font-size: 2.7rem;
  }
}

.navigation--secondary {
  --navigation-color: var(--default-text-color);
  font-size: 1.4rem;
}
.navigation--secondary .navigation__list > li {
  padding-top: 0.4rem;
  padding-bottom: 0.3rem;
}
@media (--show-desktop-menu) {
  .navigation--secondary a:--focus {
    color: var(--color-primary);
  }
}
@media (--show-mobile-menu) {
  .-is-mobile-menu-open .navigation--secondary {
    --navigation-color: var(--color-white);
  }
  .-is-mobile-menu-open .navigation--secondary .navigation__sublist {
    text-align: center;
  }
  .-is-mobile-menu-open .navigation--secondary .navigation__sublist li {
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    margin-top: 0.6em;
  }
  .-is-mobile-menu-open .navigation--secondary .navigation__sublist a {
    opacity: 0.6;
    font-size: 90%;
  }
}

.pagination {
  align-items: center;
  display: flex;
  height: 2em;
  justify-content: center;
  margin-bottom: 4em;
  color: var(--default-text-color);
}
.pagination__numbers {
  display: flex;
  margin: 0 0.8em;
}
.pagination__numbers > li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
  margin: 0 0.3em;
  position: relative;
  text-align: center;
}
.pagination__numbers > li:--focus > a {
  text-decoration: none;
}
.pagination__numbers > li.-is-active {
  color: var(--color-primary);
}
.pagination__numbers > li > a {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 0.5em;
  width: 100%;
  text-decoration: none;
  color: inherit;
}
.pagination__numbers > li > a:--focus {
  text-decoration: underline;
}
.pagination__numbers > li > a:visited {
  color: inherit;
}
.pagination__previous, .pagination__next {
  color: var(--color-primary);
  background-color: var(--color-secondary);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  text-indent: -999px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
}
.pagination__previous::after, .pagination__next::after {
  content: "";
  background-color: currentColor;
  mask-image: url("../images/arrow.svg");
  mask-size: contain;
  mask-position: center;
  display: inline-block;
  width: 1.8rem;
  height: 1.1rem;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.pagination__previous:--focus, .pagination__next:--focus {
  color: var(--color-green-dark);
}
.pagination__next::after {
  transform: scaleX(-1) translateY(-50%) translateX(-50%);
  left: 0;
}

.product-specs {
  font-size: 1.4rem;
  line-height: 1.25;
  width: 100%;
  margin: 2em 0 0;
  table-layout: fixed;
}
.product-specs th {
  color: var(--color-primary);
  font-weight: bold;
  padding-bottom: 0.4em;
  width: 33.3%;
}

.tags.-pull-up {
  margin-top: -1em;
}
.tags__list {
  display: inline-block;
}
.tags__list > li {
  display: inline-block;
  margin-bottom: 0.8rem;
}
.tags__list > li:not(:last-child) {
  margin-right: 0.4rem;
}
.tags__filter-label {
  font-family: var(--font-secondary);
}
.tags__item {
  display: block;
  font-size: 1.4rem;
  line-height: 1.1;
  background-color: var(--color-white);
  border-radius: 0.4rem;
  border: 1px solid var(--color-tertiary-dark);
  padding: 0.6rem 0.6rem 0.4rem;
  font-family: inherit;
  color: inherit;
}

a.tags__item {
  text-decoration: none;
}
a.tags__item:--focus {
  border-color: var(--color-primary);
}

label.tags__item {
  cursor: pointer;
  position: relative;
  padding-right: 1.5em;
}
label.tags__item::after {
  content: "";
  mask-image: url("../../images/cross.svg");
  background-color: currentColor;
  mask-size: 1rem;
  display: inline-block;
  width: 0.7em;
  height: 100%;
  mask-position: center;
  position: absolute;
  right: 0.6rem;
  top: 0.1rem;
}
label.tags__item:--focus::after {
  background-color: var(--color-primary);
}

.h-has-dynamic-content {
  position: relative;
}
.h-has-dynamic-content::after {
  content: "";
  display: block;
  position: absolute;
  top: -2rem;
  left: 0;
  right: 0;
  bottom: -2rem;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease;
  background-size: 6rem 6rem;
  background-repeat: repeat;
  background-image: linear-gradient(45deg, white 25%, transparent 25%, transparent 50%, white 50%, white 75%, transparent 75%, transparent);
  animation: loading 0.6s linear infinite;
}
.h-has-dynamic-content.-is-loading::after {
  opacity: 1;
  z-index: 1;
}

@keyframes loading {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 12rem 6rem;
  }
}
.h-margin-bottom-2 {
  margin-bottom: 2em;
}

.h-margin-bottom-4 {
  margin-bottom: 4em;
}

.h-margin-bottom-5 {
  margin-bottom: 5em;
}

.h-margin-top-1 {
  margin-top: 1em;
}

.h-text-align-center {
  text-align: center;
}

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
.h-hidden {
  display: none !important;
  visibility: hidden;
}

.h-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.1rem;
}

.h-visually-hidden--focusable {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.1rem;
}
.h-visually-hidden--focusable:active, .h-visually-hidden--focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.h-preload-image {
  background-image: var(--preload-image);
  background-size: cover;
}