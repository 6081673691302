/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 * Allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
 */
@mixin visually-hidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: .1rem;
    margin: -.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: .1rem;
}
