:root {
    /* stylelint-disable declaration-colon-space-after  */
    --table-border-color:               transparent;
    --table-border-width:               0;

    --table-head-border-color:          var(--color-tertiary-dark);
    --table-head-border-width:          .1rem;
    --table-head-color:                 var(--default-text-color);
    --table-head-background-color:      transparent;

    --table-row-border-color:           transparent;
    --table-row-border-width:           0;
    --table-row-color:                  inherit;
    --table-row-background-color-even:  var(--color-tertiary);
    --table-row-background-color-odd:   transparent;
    /* stylelint-enable */
}

@custom-media --table-less-padding (width < 600px);

table:not([class]), .table {
    border: var(--table-border-width) solid var(--table-border-color);
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 90%;
    margin-bottom: 2em;
    margin-top: 2em;
    max-width: 100%;
    table-layout: fixed;
    width: 100%;

    @media (--table-less-padding) {
        font-size: 80%;
    }

    tr {
        &:nth-child(even) {
            background-color: var(--table-row-background-color-even);
        }

        &:nth-child(odd) {
            background-color: var(--table-row-background-color-odd);
        }

        &:hover {
            th, td {
                color: var(--color-black);
            }
        }
    }

    th, td {
        padding: .9em .5em;

        @media (--table-less-padding) {
            padding: .5em .25em;
        }
    }

    th {
        background: var(--table-head-background-color);
        border-bottom: var(--table-head-border-width) solid var(--table-head-border-color);
        color: var(--table-head-color);
        text-align: left;
        vertical-align: middle;
    }

    td {
        border-bottom: var(--table-row-border-width) solid var(--table-row-border-color);
        color: var(--table-row-color);
    }

    tr:last-child td {
        border-bottom-color: var(--table-border-color);
    }

    thead {
        th {
            vertical-align: bottom;
        }
    }

    tbody {
        th, td {
            vertical-align: top;
        }
    }
}
