@use '../mixins/container';
@use '../elements/button';

@custom-media --l-highlights-grid-4 (min-width: 1100px);
@custom-media --l-highlights-grid-3 (min-width: 800px);
@custom-media --l-highlights-grid-2 (min-width: 550px);
@custom-media --l-highlights-grid-1 (max-width: 549px);

:root {
    --highlights-grid-background-color: var(--color-green-dark);
    --highlights-grid-background-color-hover: color-mod(var(--color-green-dark) shade(20%));
}

.l-highlights-grid {
    @include container.outer();
    color: var(--color-white);
    font-size: 1.5rem;
    display: grid;
    gap: .8rem;
    margin-bottom: 4rem;

    @media (--l-highlights-grid-1) {
        grid-template: none;
    }

    @media (--l-highlights-grid-2) {
        grid-template:
            'highlight1  highlight1'
            'highlight1  highlight1'
            'highlight2  highlight3'
            'highlight2  highlight4'
            'highlight5  highlight6'
            'highlight5  highlight7'
            'highlight8  highlight8'
            'highlight9  highlight9'
            'highlight10 highlight11'
            'highlight12 highlight13'
            / 1fr        1fr;
    }

    @media (--l-highlights-grid-3) {
        grid-template:
            'highlight1  highlight1  highlight2'
            'highlight1  highlight1  highlight2'
            'highlight5  highlight3  highlight4'
            'highlight5  highlight9  highlight9'
            'highlight6  highlight8  highlight8'
            'highlight7  highlight10 highlight11'
            'highlight12 highlight10 highlight13'
            / 1fr        1fr         1fr;
    }

    @media (--l-highlights-grid-4) {
        grid-template:
            'highlight1  highlight1  highlight2  highlight3'
            'highlight1  highlight1  highlight2  highlight4'
            'highlight5  highlight6  highlight8  highlight8'
            'highlight5  highlight9  highlight9  highlight7'
            'highlight10 highlight11 highlight12 highlight13'
            / 1fr        1fr         1fr         1fr;
    }

    > * {
        background-color: var(--highlights-grid-background-color);
        padding: 3rem;
        min-height: 34.4rem;

        &:first-child {
            @media (--l-highlights-grid-2) {
                min-height: 68.8rem;
            }
        }

        &.-background-color-alt {
            --highlights-grid-background-color: var(--color-primary);
            --highlights-grid-background-color-hover: color-mod(var(--color-primary) shade(20%));
        }

        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    > a {
        &:--focus {
            background-color: var(--highlights-grid-background-color-hover);
        }
    }

    @media (--l-highlights-grid-2) {
        @for $i from 1 through 13 {
            > :nth-child(#{$i}) {
                grid-area: highlight#{$i};
            }
        }
    }

    a {
        color: currentColor;
        text-decoration: none;

        &:visited {
            color: currentColor;
        }
    }

    p {
        margin-bottom: 1em;
    }

    button:not([class]), .button:not([class*='-color-']), .button--arrow:not([class*='-color-']) {
        // Change default button color because of dark green background
        @include button.color-secondary();
    }
}
