/* form__FIELD */

// Defaults & resets
%input-base-reset {
    font-size: 1.6rem;
    line-height: normal;
    margin: 0;
    width: 100%;
    padding: 1rem;
    font-family: var(--font-primary);
}

%input {
    @extend %input-base-reset;
    border-radius: 4px;
    box-sizing: border-box;
    display: inline-block;
    padding: 1.2rem;
    word-break: normal;
    border: 1px solid var(--color-tertiary-dark);
    color: var(--default-text-color);
    transition: background-color .2s ease-in-out, border-color .2s ease-in-out;

    &::placeholder {
        color: color-mod(var(--default-text-color) a(80%));
    }

    &:focus {
        background-color: color-mod(var(--color-tertiary) a(20%));
        outline: none;
        border-color: var(--color-tertiary-dark);

        &:valid {
            border-color: var(--color-green-dark);
            background-color: color-mod(var(--color-green) a(15%));
        }
    }

    &[aria-invalid] {
        border-color: var(--color-red);
        background-color: color-mod(var(--color-red) a(10%));
    }
}

input:not([class]),
.input {
    @extend %input;

    &[type='checkbox'], &[type='radio'] {
        display: inline-block;
        margin-left: .3rem;
        position: absolute;
        width: auto;
        margin-top: .7rem;
        left: 0;

        &:focus {
            outline: auto;
        }
    }
}

textarea:not([class]),
.textarea {
    @extend %input;
    line-height: 1.5;
}
