$bottom-margins: 2, 4, 5;

@each $margin in $bottom-margins {
    .h-margin-bottom-#{$margin} {
        margin-bottom: #{$margin}em;
    }
}

.h-margin-top-1 {
    margin-top: 1em;
}
