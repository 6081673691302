@use '../mixins/container';

.l-filter {
    &__options {
        border-bottom: 2px solid var(--color-tertiary);

        &-inner {
            @include container.inner();
        }
    }

    &__selection {
        @include container.inner();
        padding-top: 5rem;
        padding-bottom: 3rem;
    }
}
