.pagination {
    align-items: center;
    display: flex;
    height: 2em;
    justify-content: center;
    margin-bottom: 4em;
    color: var(--default-text-color);

    &__numbers {
        display: flex;
        margin: 0 .8em;

        > li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 1;
            margin: 0 .3em;
            position: relative;
            text-align: center;

            &:--focus {
                > a {
                    text-decoration: none;
                }
            }

            &.-is-active {
                color: var(--color-primary);
            }

            > a {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                padding: 0 .5em;
                width: 100%;
                text-decoration: none;
                color: inherit;

                &:--focus {
                    text-decoration: underline;
                }

                &:visited {
                    color: inherit;
                }
            }
        }
    }

    &__previous, &__next {
        color: var(--color-primary);
        background-color: var(--color-secondary);
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        text-indent: -999px;
        overflow: hidden;
        position: relative;
        text-decoration: none;

        &::after {
            content: '';
            background-color: currentColor;
            mask-image: url('../images/arrow.svg');
            mask-size: contain;
            mask-position: center;
            display: inline-block;
            width: 1.8rem;
            height: 1.1rem;
            transform: translateY(-50%) translateX(-50%);
            position: absolute;
            top: 50%;
            left: 50%;
        }

        &:--focus {
            color: var(--color-green-dark);
        }
    }

    &__next {
        &::after {
            transform: scaleX(-1) translateY(-50%) translateX(-50%);
            left: 0;
        }
    }
}
